.blue {
    color: #71AAFF !important;
}

.blue {
    color: #71AAFF !important;
}

.blue .menu-items,
.blue .menu-items .nav-label,
.blue .menu-items .dropdown-content .dropdown-item,
.blue .signup button {
    color: #71AAFF !important;
}

.blue .signup button {
    border: 2px solid #71AAFF !important;
}

/* Ensure dropdown icon also changes color */
.blue .menu-items .nav-label .dropdown-icon {
    color: #71AAFF !important;
}


.top-nav {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0px;

    .logo img {
        width: 120px;
        height: 120px;
    }

    .menu-items {
        display: flex;
        gap: 20px;
        color: #D0E1FA;
        font-size: 16px;
        font-weight: 500;

        .nav-label {
            display: flex;
            gap: 8px;
            cursor: pointer;
            padding: 10px;
            position: relative;
        }

        .nav-label:hover .dropdown-icon {
            transform: rotate(180deg);
        }

        .dropdown-icon {
            font-size: 12px;
            transition: transform 0.3s ease-in-out;
        }

        .transparent-dropdown {
            background: transparent !important;
            box-shadow: none !important;
            border: none !important;
            padding: 5px;
        }

        .dropdown-content {
            position: absolute;

            .dropdown-item {
                /* position: absolute; */
                padding: 8px 15px;
                color: #D0E1FA;
                font-size: 16px;
                font-weight: 500;
                border-radius: 10px;
                transition: background 0.3s ease-in-out;
                cursor: pointer;
            }

        }

        /* ✅ Styling dropdown items */

        .dropdown-item:hover {
            background: rgba(255, 255, 255, 0.2);
        }

    }

    .signup {
        margin-right: 30px;

        button {
            /* padding: 10px 5px; */
            height: 45px;
            color: white;
            background-color: transparent;
            border: 2px solid white;
            border-radius: '28px';
            padding: 0px 20px;
        }
    }

}