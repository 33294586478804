.reconfirm-interview-div{
  display: flex;
  gap: 35px;
}
.head-reconfirm-container{
}
.head-cand-interviews{
  
}
.reconfirm-interview-div{
  display: flex;
  justify-content: space-between;


}
.cand-dash-interviewcards-div{
  padding: 10px;
  border-radius: 10px;
  box-shadow: 
  0px 8px 8px rgba(0, 0, 0, 0.1), 
  4px 0px 4px rgba(0, 0, 0, 0.1);
}
.dashboard-cand-applications{
  /* background-color: red; */
}
