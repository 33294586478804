.add-rec-form {
	max-width: 400px;
	margin: auto;
	padding: 24px;
	display: flex;
	flex-direction: column;
	gap: 16px;
	border-radius: 10px;
}

.add-rec-form button{
	background-color: var(--color-primary);
	font-size: 14px;
	padding: 4px 8px;
}

.add-rec-form .ant-btn span {
	font-weight: 400;
}

.green {
	color: green;
	margin-bottom: 16px;
	text-align: center;
}

.error {
	color: red;
	margin-bottom: 16px;
	text-align: center;
}

.add-rec-btn {
	float: right;
}

.add-rec-btn .ant-btn {
	font-size: 16px;
	padding: 5px 10px;
	border: 2px solid var(--color-primary);
}

.add-rec-btn .ant-btn span {
	font-weight: 400;
}
