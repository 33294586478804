.client-dashboard .sec-1 {
    display: flex;
    gap: 40px;
}

.client-dashboard .sec-1 a {
    color: var(--color-primary);
    border: 2px solid var(--color-primary);
    padding: 5px 10px;
    border-radius: 10px;
}

.client-dashboard .sec-1 div {
    font-size: 20px;
    font-weight: 500;
}

.client-dashboard .job-postings {
    margin: 20px;
}

.client-dashboard .sec-2 {
    display: flex;
    gap: 20px;
    margin-top: 20px;

}

.client-dashboard .sec-1 {
    display: flex;
    gap: 10px;
}

.client-dashboard .sec-1 .box {
    width: 19vw;
    border-left: 5px solid #29C5EE;
    padding: 5px 10px;
    border-radius: 15.528px;
    background: #F7F7F7;

    .line1 {
        display: flex;
        justify-content: space-between;
        margin-top: 10px;

        .job-title {
            color: #1681FF;
            font-size: 17px;
            font-weight: 700;
        }

        img {
            cursor: pointer;
        }

    }

    .posted {
        color: #555;
        font-size: 11.646px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
    }

    .line2 {
        display: flex;
        gap: 10px;
        margin-top: 5px;

        .option {
            border-radius: 23.292px;
            display: flex;
            align-items: center;
            gap: 5px;
            background: rgba(22, 129, 255, 0.05);
            padding: 8px 10px;


            img {
                height: 16px;
            }

            span {
                color: #555;
                font-size: 12px;
                font-weight: 500;
            }
        }
    }

    .line3 {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        margin-top: 10px;

        .value {
            color: #1681FF;
            font-size: 38px;
            font-weight: 700;

            .value {
                padding-right: 5px;
            }
        }

        .name {
            color: #555;
            font-size: 13px;
            font-weight: 400;
        }

        .last-week {
            color: #00B85E;
            font-size: 11.64px;
            font-weight: 400;
            padding-bottom: 10px;
        }

    }
}

.client-dashboard .sec-2 .box {
    border-radius: 10px;
    border: 1px solid var(--gray-gray-20, rgba(162, 161, 168, 0.20));
    width: 16vw;
    height: 18vh;
    padding-left: 16px;
    padding-top: 16px;
    border-radius: 15.528px;
    background: #F7F7F7;

    .line1 {
        display: flex;
        align-items: center;

        img {
            border-radius: 5px;
            background: #1681FF0D;
            margin-right: 10px;
        }
    }

    .line2 {
        .value {
            color: #16151C;
            font-weight: 600;
            font-size: 30px;
            margin-bottom: 15px;
        }

        border-bottom: 1px solid #A2A1A833;
    }

    .text {
        color: #A2A1A8;
        font-weight: 300;
        font-size: 12px;
        padding-top: 15px;
        padding-bottom: 5px;
    }

}

.client-dashboard .interviewers {
    display: flex;
    gap: 3px;

    .today-interviews {
        padding: 20px 14px;
        width: 300px;
        margin-top: 10px;
        border: 1px solid #A2A1A833;
        border-radius: 10px;
        gap: 5px;

        .heading {
            color: #1681FF;
            font-weight: 600;
            font-size: 18px;
        }

        .item {
            display: flex;
            gap: 10px;
            justify-content: center;
            align-items: center;

            .time {
                color: #16151C;
                font-weight: 600;
                font-size: 16px;
            }

            .interview_details {
                border-left: 3px solid #1681FF;
                padding-left: 6px;
                fill: linear-gradient(270deg, #1681FF -15.69%, rgba(22, 129, 255, 0.00) 100%);
            }

            .interview_details .line1 {
                color: #16151C;
                font-weight: 300;
            }

            .interview_details .line2 {
                color: #16151C;
                font-weight: 600;

            }

            .no_data_ found {
                margin-top: 10px;
            }

        }
    }
}