.landing-home {
    margin: 0;
    padding: 0;

    .top-section {
        margin: 0;
        padding: 0;
    }

    .intro {
        display: flex;

        .side1 {
            margin-top: 60px;
            padding-left: 5vw;
            display: flex;
            flex-direction: column;
            /* align-items: center; */
            gap: 10px;

            .title {
                /* width: 30vw; */
                display: flex;
                flex-wrap: wrap;
                gap: 10px;
                color: #FFF;
                text-align: start;
                font-family: "Playfair Display";
                font-size: 40px;
                font-style: normal;
                font-weight: 700;
                line-height: normal;
            }

            .description {
                color: #FFF;
                /* font-family: Inter; */
                font-size: 15px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }

            .btn {
                margin-top: 30px;

                button {
                    display: flex;
                    padding: 12px 16px;
                    justify-content: center;
                    align-items: center;
                    gap: 10px;
                    color: white;
                    background-color: transparent;
                    border-radius: 24px;
                    border: 1px solid #FFF;
                }

            }
        }

        .side2 {
            position: relative;
            /* Ensure child absolute elements position correctly */
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            width: fit-content;

            img {
                width: 902px;
                height: 680px;
                aspect-ratio: 451/340;
                display: block;
            }

            .message1,
            .message2,
            .message3,
            .message4 {
                position: absolute;
                background-color: #FFD955;
                padding: 8px 15px;
                border-radius: 15px;
                color: var(--primary, rgba(0, 0, 0, 0.85));
                font-size: 16px;
                font-weight: 400;
                white-space: nowrap;
                opacity: 1;
                /* transform: translateY(-20px); */
                /* animation: fadeIn 1s ease-in-out forwards; */
            }

            .message1 {
                top: 1%;
                left: 31%;
                background-color: #FFD955;
                color: var(--primary, rgba(0, 0, 0, 0.85));
                animation-delay: 0.2s;
            }

            .message2 {
                top: 15%;
                left: 9%;
                background-color: #37D15D;
                color: white;
                animation-delay: 0.4s;
            }

            .message3 {
                top: 29%;
                left: 13%;
                background-color: #2E9DFB;
                color: white;
                animation-delay: 0.4s;
            }

            .message4 {
                top: 42%;
                left: 29%;
                background-color: #9149ED;
                color: white;
                animation-delay: 0.8s;
            }


            @keyframes fadeIn {
                0% {
                    opacity: 0;
                    transform: translateY(-20px);
                }

                100% {
                    opacity: 1;
                    transform: translateY(0);
                }
            }
        }

    }

    .content-section {
        height: 100%;

        .trusted-companies {
            padding: 10vh 0px;
            display: flex;
            align-items: center;
            flex-direction: column;

            .matter {
                width: 50vw;
                color: #8B8A8A;
                text-align: center;
                font-size: 45px;
                font-style: normal;
                font-weight: 600;
                line-height: 109.867%;
                letter-spacing: -0.24px;

                .fifty {
                    color: #F46EBE;
                    /* font-family: Inter; */
                    font-size: 54px;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 108.939%;
                    letter-spacing: -0.32px;
                }
            }

            .logos {
                width: 80vw;
                display: flex;
                flex-wrap: wrap;
                margin-top: 50px;
                gap: 50px;

                img {
                    width: 110px;
                    height: 18px;
                    flex-shrink: 0;
                }
            }

            .carousel-container {

                width: 100%;
                overflow: hidden;
                position: relative;
                display: flex;
                align-items: center;
                justify-content: center;
                /* background: #f8f8f8; */
                padding: 20px 0;
                margin-top: 20px;

                .carousel-track {
                    display: flex;
                    gap: 50px;
                    white-space: nowrap;
                    align-items: center;

                    .logo-slide img {
                        width: 120px;
                        height: 60px;
                        object-fit: contain;
                        filter: grayscale(100%);
                        transition: filter 0.3s ease-in-out;
                    }

                    .logo-slide img:hover {
                        filter: grayscale(0%);
                    }

                }

                @keyframes scroll {
                    to {
                        transform: translateX(-100%)
                    }
                }
            }

        }
    }

    .section2 {
        /* border: 2px solid black; */
        padding-top: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .matter {
            width: 30vw;
            color: #4D5163;
            text-align: center;
            font-size: 45px;
            font-style: normal;
            font-weight: 600;

            .fifty {
                color: #F46EBE;
            }
        }

        .features {
            img {
                /* height: 500px;
                width: 500px; */
            }
        }

        .subscribers {
            margin-top: 10px;
            display: flex;
            padding: 40px;
            width: 80%;
            justify-content: space-between;

            .counter {
                display: flex;
                flex-direction: column;
                /* justify-content: space-between; */

                .count {
                    font-size: 70px;
                    color: #4394DF;
                    font-weight: 600;
                }

                .about {
                    color: #4D5163;
                    font-weight: 400;
                    font-size: 14px;
                }
            }
        }
    }

    .core-features {
        padding: 10vh 5vw;
        display: flex;
        flex-direction: column;
        align-items: center;

        .name {
            width: 20vw;
            color: #4D5163;
            font-weight: 600;
            font-size: 52px;
            display: flex;
            line-height: 109.867%;
            letter-spacing: -0.24px;
            gap: 10px;
            flex-direction: column;

            .pink {
                color: #F46EBE;
            }
        }

        .matter {
            margin-top: 10px;
            width: 40vw;
            color: #4D5163;
            text-align: center;
            /* font-family: Inter; */
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }

        .core-features-list {
            margin-top: 26px;
            width: 80%;

            display: flex;
            flex-wrap: wrap;
            gap: 10px;

            .feature-card {
                border-radius: 12px;
                background: #FFF;
                box-shadow: 0px 0px 16px 0px rgba(77, 104, 195, 0.12);
                padding: 26px 28px;
                width: 40%;

                img {
                    height: 30px;
                }

                .name {
                    color: #56A8FD;
                    /* font-family: Lato; */
                    font-size: 18px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                }

                .feature-des {
                    /* width: 50px; */
                    overflow: hidden;
                    color: #565E6C;
                    text-overflow: ellipsis;
                    /* font-family: Lato; */
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;

                }

            }
        }
    }

    .plans {
        display: flex;
        flex-direction: column;
        padding: 10vh 10vw;
        align-items: center;

        .name {
            width: 30vw;
            color: #4D5163;
            text-align: center;
            /* font-family: "DM Serif Display"; */
            font-size: 40px;
            font-style: normal;
            font-weight: 400;
            line-height: 110.867%;
            letter-spacing: -0.24px;

            .pink {
                color: #F46EBE;
                ;
            }
        }

        .matter {
            width: 520px;
            color: #4D5163;
            margin-top: 20px;
            font-size: 16px;
            font-weight: 400;
        }

        .toggler {
            margin-top: 20px;
            display: flex;
            gap: 10px;
            align-items: flex-end;
            justify-content: center;

            .planss {
                color: #73A1E1;
            }
        }

        .plans-list {
            display: flex;
            gap: 20px;
            margin-top: 60px;

            .plan-card {
                box-shadow: 0px 0px 3px 3px rgb(174, 171, 171);
                padding: 15px 15px;
                border-radius: 24px;
                background: #FFF;
                width: 260px;
                padding-bottom: 30px;
                /* height: 417px; */
                flex-shrink: 0;
                display: flex;
                flex-direction: column;

                .name {
                    text-align: start;
                    color: #104259;
                    /* font-family: Inter; */
                    font-size: 25px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                }

                .features {
                    margin-top: 15px;
                    display: flex;
                    flex-direction: column;
                    gap: 15px;
                    color: #d6e4ea;

                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;

                    .feature {
                        display: flex;
                        gap: 20px;
                    }

                    .enabled {
                        /* opacity: 1; */
                        color: #104259;
                    }
                }

                .price {
                    margin-top: 25px;
                    opacity: 0.96;
                    color: #104259;
                    /* font-family: Inter; */
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: normal;
                }

                .btn {
                    width: 100%;
                    display: flex;
                    margin-top: 20px;
                    justify-content: center;

                    button {
                        border-radius: 8px;
                        width: 80%;
                        background-color: #F8F4FF;
                        color: #45CAFF;
                        font-size: 16px;
                        font-weight: 600;
                    }
                }
            }

            .active {
                position: relative;
                top: -40px;
                /* right: -20px; */
                border-radius: 24px;
                background: linear-gradient(160deg, #114C68 3.5%, #00A7F4 49.44%, #114C68 99.24%);
                color: white !important;
            }

            .active * {
                color: inherit !important;
                /* Inherits white color for all child elements */
            }

            .active .features .disabled {
                color: #9ca1a3 !important;
            }

            .active .btn button {
                background-color: #45CAFF;
            }
        }

    }

    .reviews {
        margin-top: 20px;
        padding: 20px 30px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .name {
            width: 30vw;
            color: #4D5163;
            text-align: center;
            font-size: 48px;
            font-style: normal;
            font-weight: 600;
            line-height: 109%;

            .pink {
                color: #F46EBE;
            }
        }

        .matter {
            width: 45vw;
            margin-top: 10px;
            color: #4D5163;
            text-align: center;
            /* font-family: Inter; */
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }

        .reviews-list {
            margin-top: 20px;
            display: flex;
            gap: 30px;
            overflow: auto;
            width: 80%;

            .review-card {
                width: 250px;
                border-radius: 10px;

                .video {
                    border-radius: 20px;
                    width: 100%;
                    max-width: 450px;
                    aspect-ratio: 10 / 16;
                    border-radius: 12px;
                    position: relative;
                    overflow: hidden;

                    video {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }

                    .buttons {
                        margin: 0;
                        padding: 5px 10px;
                        width: 85%;
                        border-radius: 8px;
                        background: rgba(61, 117, 177, 0.50);
                        backdrop-filter: blur(6.349999904632568px);
                        position: absolute;
                        bottom: 10px;
                        left: 10px;
                        display: flex;
                        align-items: center;
                        gap: 5px;
                        /* justify-content: ;    */

                        button {
                            background-color: transparent;
                            border: none;
                        }

                        .user {
                            display: flex;
                            flex-direction: column;
                            justify-content: center;
                            /* align-items: center; */
                            gap: 1px;

                            .name {
                                margin-top: 0;
                                margin: 0;
                                padding: 0;
                                display: flex;
                                justify-content: start;
                                color: #FFF !important;
                                text-align: center;
                                /* font-family: Inter; */
                                font-size: 12px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: normal;
                                text-transform: capitalize;
                            }

                            .profession {
                                color: #FFF;
                                /* font-family: Inter; */
                                font-size: 8px;
                                font-style: normal;
                                font-weight: 400;
                                line-height: normal;
                                /* text-transform: capitalize; */
                            }
                        }
                    }
                }

                .comment {
                    background-color: white;
                    /* width: 100%; */
                    padding: 20px;
                    border-radius: 15px;

                    .user {
                        display: flex;
                        flex-direction: column;
                        color: #4D5163;
                        gap: 5px;

                        .name {
                            text-align: start;
                            /* font-family: Poppins; */
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 100%;
                            text-transform: uppercase;
                        }

                        .profession {
                            font-size: 12px;
                            font-weight: 300;
                        }

                    }

                    .comment-des {
                        margin-top: 10px;
                        color: #4D5163;
                        text-overflow: ellipsis;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                    }

                }

                .video-comment {
                    padding: 20px;
                    background-color: white;
                    position: relative;

                    .video-container {
                        position: relative;
                        width: 100%;

                        video {
                            width: 100%;
                            height: auto;
                            object-fit: cover;
                            display: block;
                        }

                        .button-container {
                            position: absolute;
                            bottom: 38%;
                            left: 35%;
                            opacity: 0;

                            button {
                                height: 0px;
                                width: 0px;
                                background-color: transparent;
                                color: white;
                                border: none;
                                font-size: 16px;
                                cursor: pointer;
                                border-radius: 5px;
                            }

                            button:hover {
                                background-color: transparent !important;
                                height: 0px;
                                width: 0;
                            }

                        }

                    }

                    .video-container:hover .button-container {
                        opacity: 1;
                    }

                    .user {
                        display: flex;
                        flex-direction: column;
                        color: #4D5163;
                        gap: 5px;

                        .name {
                            text-align: start;
                            /* font-family: Poppins; */
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: 100%;
                            text-transform: uppercase;
                        }

                        .profession {
                            font-size: 12px;
                            font-weight: 300;
                        }

                    }

                    .comment-des {
                        margin-top: 10px;
                        color: #4D5163;
                        text-overflow: ellipsis;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                    }
                }
            }
        }

    }

    .faqs {
        margin-top: 20px;
        padding: 20px 30px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .name {
            width: 30vw;
            color: #4D5163;
            text-align: center;
            font-size: 48px;
            font-style: normal;
            font-weight: 600;
            line-height: 109%;

            .pink {
                color: #F46EBE;
            }
        }

        .matter {
            width: 45vw;
            margin-top: 10px;
            color: #4D5163;
            text-align: center;
            /* font-family: Inter; */
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }

        .faqs-list {
            width: 90%;
            margin-top: 20px;
            display: flex;
            flex-direction: column;
            gap: 20px;
            padding-bottom: 30px;

            .faq {
                border-radius: 12px;
                background: #FFF;
                box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
                backdrop-filter: blur(100px);
                padding: 16px 20px;
                gap: 20px;
                align-self: stretch;
                color: #58A1FF;
                /* font-family: Lato; */
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;

                .topline {
                    display: flex;
                    cursor: pointer;
                    justify-content: space-between;

                    .btn.rotate-180 {
                        transform: rotate(180deg);
                        transition: 0.5s;
                    }

                    .btn.rotate-0 {
                        transition: 0.5s
                    }
                }

                .answer {
                    margin-top: 10px;
                    color: #727272;
                    /* font-family: Inter; */
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }
            }

        }
    }
}