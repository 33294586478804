.title {
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
}

.job-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 20px;
}

.job-card {
    background: #fff;
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    width: 100%;
    max-width: 600px;
    margin: auto;
}

.job-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid #f0f0f0;
    padding-bottom: 10px;
    margin-bottom: 15px;
}

.job-title {
    font-size: 20px;
    font-weight: bold;
    color: #1890ff;
}

.job-date {
    font-size: 14px;
    color: #777;
}

.candidate-list {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.candidate-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #f9f9f9;
    padding: 10px;
    border-radius: 5px;
    transition: background 0.3s;
}

.candidate-item:hover {
    background: #e6f7ff;
}

.candidate-name {
    font-size: 16px;
    font-weight: 500;
}

.no-candidates {
    color: #888;
    text-align: center;
    font-style: italic;
}

.replacement {
    background: aqua;
}