.job-details-container .action-btns .ant-btn {
	font-size: 16px;
}

.job-details-container .action-btns .ant-btn span {
	font-weight: 400;
}

.scorecard-resume-sare-cards {
	display: flex;
	padding: 25px;

}

pre {
	white-space: break-spaces;
}

.questionary {
	margin-top: 24px;
	background-color: #fff;
	padding: 20px;
	box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
	border-radius: 16px;

	p {
		text-align: left !important;
		margin-left: 16px;
	}

	.quest {
		padding: 10px;
		border-bottom: 1px solid #ccc;
	}
}

.jd-rec-main {
	/* display: grid; */
	/* grid-template-columns: 1.5fr 1fr; */
	/* Ensures left and right sections */
	gap: 10px;
	align-items: start;
}

.jd-rec-main .right-div {
	background-color: #fff;
	box-shadow: 0 2px 2px rgba(0, 0, 0, 0.15);
	padding: 16px;
	position: sticky;
	top: 20px;
	/* Makes it stick when scrolling */
	padding-right: 30px;
	width: 100%;
	/* Ensure it takes full width in its column */
}

/* button {
	background-color: var(--color-primary);
	color: #fff;
	height: 48px;
}

.job-details-container button:hover {
	background-color: var(--color-primary) !important;
	color: #fff !important;
	border: none !important;
} */


.right-div .generate-questionary {
	display: flex;
	gap: 8px;

}

.job-details-manager-main {
	margin: 0px;
	/* width: 100%; */
	background-color: #fff;

	max-width: 100%;
}