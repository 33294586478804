.cand-dash-interview-card .card-container {
  position: relative;
  border-radius: 8px;
  background-color: #f8f9fa;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 12px 14px;
  margin: 10px 0;
  min-width: 540px;
  max-width: 700px;
  overflow: hidden;
}

.cand-dash-interview-card .card-container::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 4px;
  background-color: #0d6efd;
  border-radius: 4px 0 0 4px;
}

.cand-dash-interview-card .card-content {
  display: flex;
  justify-content: space-between;
  align-items: center;
  h3{
    margin: 0px;
    padding: 0px;

  }
}

.cand-dash-interview-card .card-info {
  flex: 1;
}

.cand-dash-interview-card.card-title {
  font-size: 18px;
  font-weight: 600;
  margin: 0 0 5px 0;
  color: #212529;
}

.cand-dash-interview-card .card-time {
  font-size: 14px;
  color: #6c757d;
  margin: 5px 0;
  text-align:left ;
}

.cand-dash-interview-card .card-company {
  font-size: 14px;
  color: #6c757d;
  margin: 5px 0;
  text-align: left;
}

.cand-dash-interview-card .view-button {
  background-color: #0d6efd;
  color: white;
  border: none;
  border-radius: 4px;
  padding: 0px 8px;
  font-size: 13px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.cand-dash-interview-card .view-button:hover {
  background-color: #0b5ed7;
}

