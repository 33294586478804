/* Container styling */
.candidate-table-container {
  padding: 20px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}
.search-sceduletable{
  width: 95%;
  margin: 0px auto;
  border: 1px solid #A2A1A866;
  padding: 20px;
  border-radius: 8px;
}

/* Search bar styling */
.search-container {
  margin-bottom: 20px;
}

.search-input {
  width: 100%;
  border-radius: 20px;
  padding: 8px 12px;
  border: 1px solid #e8e8e8;
}

.search-input .ant-input {
  font-size: 14px;
}

.search-icon {
  color: #bfbfbf;
}

.styled-table .ant-table {
  border-radius: 8px !important;
  width: 100%;
  /* margin: 0px auto; */
}


.styled-table .ant-table-thead > tr > th {
  border-top: none !important;
}

.styled-table .ant-table-thead > tr > th {
  border-bottom: 3px solid #1890ff !important; 
}

.styled-table .ant-table-tbody > tr > td {
  border-top: none !important;
}

.styled-table .ant-table-tbody > tr > td {
  border-bottom: 1.4px solid #d9d9d9 !important; 
  padding: 10px  !important;
}

.styled-table .ant-table-cell {
  text-align: center;
  padding: 10px !important;
}
.styled-table .ant-table-thead > tr > th {
  text-align: center;
}

.styled-table .ant-table-tbody > tr > td {
  text-align: center;
}

 .styled-table .scedule-button{
  background-color: none;
  background: #F3F9FF;
  border:none;
  padding: 4px 10px !important;
  margin: none;
  color: #7EB9FF;
  font-size: 14px;

}
.scedule-button:hover{
  background-color: none !important;
  background: #F3F9FF !important;
  border:none !important;
  margin: none !important;
  color: #7EB9FF !important;
}

.styled-table .ant-table-thead > tr > th {
  background-color: #fafafa !important;
  color: #666 !important;
  font-weight: 500  !important;
  padding: 16px !important;
  border-bottom: 1px solid #f0f0f0 !important;
}

.styled-table .ant-table-tbody > tr > td {
  padding: 12px !important;
  /* border-bottom: 1px solid #f0f0f0 !important; */
}

.styled-table .ant-table-tbody > tr:hover > td {
  background-color: #fafafa !important;
}

/* Schedule button styling */
.schedule-button {
  background-color: #e6f7ff !important;
  color: red !important;
  /* color: #1890ff !important; */
  border: none !important;
  border-radius: 16px !important;
  padding: 6px 12px !important;
  font-size: 12px !important;
  cursor: pointer !important;
  transition: all 0.3s !important;
}

.schedule-button:hover {
  background-color: #bae7ff !important;
}

/* Remove table footer if not needed */
.styled-table .ant-table-pagination {
  display: none !important;
}

/* Custom row styling */
.styled-table .ant-table-tbody > tr > td {
  font-size: 14px !important;
  /* color:red !important; */
}

/* Ensure the table takes full width */
.styled-table {
  width: 100% !important;
}