* {
	font-family: 'poppins';
	background-color: transparent;
}

:root {
	--font-family-primary: "Mulish", sans-serif;
	--font-family-secondary: "lexend", sans-serif;
	--color-primary: #001744;
	--color-white: #FFFFFF --color-secondary: #4c49ed;
	--color-bg: #F8F8F8;
	--color-logo-babypink: #f9b2bc;
	--color-logo-blue: #56a8fd;
	--color-logo-purple: #a16afe;
	--color-logo-pink: #d142f5;
	--color-logo-lightpink: #f46ebe;
}