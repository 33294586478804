.name-email-div{
  display: flex;
  gap: 50px;
}
.profile-form-container{
  display: flex;
  width: 90%;
}
.form-element-style{
  display:  flex;
  align-items: center;
   gap: 10px;
   background-color:#F0F0F0;
    border-radius:8px;
    padding: 10px 15px;
}
.first-middle-last-names{
  display: flex;
  justify-content: space-between;
}
.form-input-style{
  border: none !important;
margin: none !important;
outline: none !important;
background-color:none;
background: none !important;
}
.name-email-div {
  display: grid;
  grid-template-columns: repeat(2, 1fr); /* 2 equal columns */
  gap: 20px; /* Space between Name & Email */
  width: 100%;
}

.form-element-style label {
  /* width: 120px;   */
  font-weight: 500;  /* Optional: Make labels slightly bold */
}