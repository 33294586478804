.view-tickets-container {
    padding: 20px;

    .header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 10px;

        h2 {
            margin: 0;
        }
    }

    .table-container {
        margin-top: 20px;
    }


}