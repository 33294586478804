.agency-candidates {
  width: 94%;
  /* margin: 0 auto; */
  padding-left: 25px;
  /* background-color: lightblue; */

  .row1 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    height: 80px;
    padding: 0px 20px;
  }

  .row2 {
    display: flex;
    flex-direction: column;
  }
}

.row1 {
  .search-btn {
    display: flex;
    align-items: center;
    padding: 0px 10px;
    background-color: #1681FF;
    border-radius: 8px;
  }

  .dropdown {
    display: flex;
    align-items: center;
    gap: 10px;
  }
}

.row2 {
  width: 100%;
  padding: 20px;

  .results-clearall {
    display: flex;
    justify-content: space-between;
  }
}

.results-clearall .results {
  display: flex;

}

.search-inputbtn-container {

  .input {
    outline: none;
    border: none;
    margin: none;
    height: 35px;
    width: 200px;
    color: white;
    text-indent: 5px;
    background-color: rgba(22, 129, 255, 1);
  }

  .input::placeholder {
    color: white;
    opacity: 1;
    /* Ensures full visibility */
  }
}

.search-btn {
  h3 {
    margin: 0px;
    font-weight: 400;
    color: white;
    font-size: 13px;
  }

}

.results .header .selected-name {
  color: rgba(232, 97, 140, 1);
  padding: 5px;
  font-size: 10px;
  border: 2px solid rgba(232, 97, 140, 1);
  border-radius: 10px;
}