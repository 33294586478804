.calendar-container {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
    "Helvetica Neue", sans-serif;
  border: 1px solid #e2e8f0;
  border-radius: 8px;
  background-color: white;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  height: 100vh;
}

.calendar-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px;
  border-bottom: 1px solid #e2e8f0;
}

.calendar-navigation {
  display: flex;
  align-items: center;
  gap: 12px;
}

.month-year {
  font-weight: 600;
  font-size: 18px;
  min-width: 25;
}

.calendar-container .nav-buttons {
  display: flex;
  gap: 4px;
  color: #3b82f6 !important;
  /* background-color: #10b981; */

}

.calendar-container .nav-button {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  border: 1px solid #e2e8f0;
  border-radius: 4px;
  background: transparent;
  cursor: pointer;
  background-color:"lightblue" !important;
}

.nav-button:hover {
  background-color: #f7fafc;
}

.today-button {
  padding: 4px 12px;
  border: none;
  background-color: transparent;
  color: #3b82f6;
  cursor: pointer;
  font-weight: 500;
}

.today-button:hover {
  text-decoration: underline;
}

.view-options {
  display: flex;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  overflow: hidden;
}

.view-button {
  padding: 6px 16px;
  border: none;
  background-color: white;
  cursor: pointer;
  font-size: 14px;
}

.view-button.active {
  background-color: #3b82f6;
  color: white;
}

/* Calendar body */
.calendar-body {
  display: flex;
  flex-direction: column;
}

/* Days header */
.calendar-days-header {
  display: flex;
  height: 60px;
  border-bottom: 1px solid #e2e8f0;
}

.time-column-header {
  width: 80px;
  flex-shrink: 0;
  border-right: 1px solid #e2e8f0;
}

.day-header {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-right: 1px solid #e2e8f0;
  padding: 8px;
}

.date-number {
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
}

.date-number.today {
  background-color: #3b82f6;
  color: white;
  border-radius: 50%;
}

.day-name {
  font-size: 12px;
  color: #64748b;
}

/* Calendar content */
.calendar-content {
  display: flex;
  position: relative;
  height: 600px; /* Fixed height for the calendar content */
}

.time-column {
  width: 80px;
  flex-shrink: 0;
  border-right: 1px solid #e2e8f0;
}

.time-slot {
  height: 60px;
  display: flex;
  align-items: flex-start;
  justify-content: flex-end;
  padding-right: 12px;
  font-size: 12px;
  color: #64748b;
  border-bottom: 1px solid #f1f5f9;
}

.calendar-grid {
  flex: 1;
  position: relative;
  overflow: hidden;
}

.time-grid-line {
  position: absolute;
  left: 0;
  right: 0;
  height: 1px;
  background-color: #f1f5f9;
}

.time-grid-line:nth-child(1) {
  top: 60px;
}
.time-grid-line:nth-child(2) {
  top: 120px;
}
.time-grid-line:nth-child(3) {
  top: 180px;
}
.time-grid-line:nth-child(4) {
  top: 240px;
}
.time-grid-line:nth-child(5) {
  top: 300px;
}
.time-grid-line:nth-child(6) {
  top: 360px;
}
.time-grid-line:nth-child(7) {
  top: 420px;
}
.time-grid-line:nth-child(8) {
  top: 480px;
}
.time-grid-line:nth-child(9) {
  top: 540px;
}
.time-grid-line:nth-child(10) {
  top: 600px;
}

.day-columns {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.day-column {
  flex: 1;
  border-right: 1px solid #f1f5f9;
  height: 100%;
}

.current-time-indicator {
  position: absolute;
  left: 0;
  right: 0;
  height: 1px;
  background-color: #3b82f6;
  border-top: 1px dashed #3b82f6;
  z-index: 10;
}

.interview {
  position: absolute;
  padding: 8px;
  border-radius: 4px;
  overflow: hidden;
  z-index: 5;
}

.interview-designer {
  background-color: #f1f5f9;
}

.interview-designer {
  background-color: #f1f5f9; /* Light gray-blue */
}

.interview-coding {
  background-color: #e0f2fe; /* Light blue */
}

.interview-hr {
  background-color: #fde68a; /* Soft yellow */
}

.interview-behavioral {
  background-color: #fef3c7; /* Pale orange */
}

.interview-technical {
  background-color: #d1fae5; /* Light green */
}

.interview-ml {
  background-color: #fbcfe8; /* Light pink */
}

.interview-frontend {
  background-color: #fef9c3; /* Light yellow */
}

.interview-backend {
  background-color: #f3e8ff; /* Lavender */
}

.interview-fullstack {
  background-color: #dbeafe; /* Soft blue */
}

.interview-database {
  background-color: #fef2f2; /* Light red */
}

.interview-product {
  background-color: #e2e8f0; /* Neutral gray */
}

.interview-cloud {
  background-color: #c7d2fe; /* Light purple */
}

.interview-devops {
  background-color: #a7f3d0; /* Soft mint */
}

.interview-security {
  background-color: #facc15; /* Bright yellow */
}

.interview-datascience {
  background-color: #bbf7d0; /* Light teal */
}

.interview-blockchain {
  background-color: #fca5a5; /* Light red */
}

.interview-ai {
  background-color: #bfdbfe; /* Soft sky blue */
}

.interview-networking {
  background-color: #fde047; /* Soft gold */
}

.interview-architecture {
  background-color: #fbcfe8; /* Soft pink */
}

.interview-cultural {
  background-color: #fef9c3; /* Light cream */
}
.interview-field {
  background-color: #3b82f6;
  color: white;
}

.interview-technical {
  background-color: #10b981;
  color: white;
}

.interview-title {
  font-weight: 500;
  font-size: 14px;
  margin-bottom: 4px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.interview-time {
  font-size: 12px;
  opacity: 0.9;
}

@media (max-width: 768px) {
  .time-column {
    width: 60px;
  }

  .time-column-header {
    width: 60px;
  }

  .time-slot {
    padding-right: 8px;
    font-size: 10px;
  }

  .interview-title {
    font-size: 12px;
  }

  .interview-time {
    font-size: 10px;
  }
}

