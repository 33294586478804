.create-job-post {
	.header {
		font-size: 28px;
		color: #171A1F;
		font-weight: 600;
		margin-left: 0px;
		margin-bottom: 10px;
	}

	.step1 {

		.terms-form {
			display: flex;
			flex-direction: column;
			align-items: center;
			text-align: center;
			margin-top: 30px;
			margin-bottom: 10px;

			.buttons {
				margin-top: 15px;
				display: flex;
				gap: 20px;

				button {
					padding: 10px 20px;
				}

				.danger {
					border: 2px solid #F45B69;
					background-color: #F45B691A;
					color: #F45B69;
				}
			}

			/* width: 60%; */
		}

		.step1-form {
			margin-top: 20px;

			label {
				color: #424955;
				font-weight: 600;
				font-size: 16px;
			}

			.form-content {
				display: flex;
				height: 35px;
				margin-top: 10px;

				.input {
					background-color: #F3F4F6;
					color: #171A1F;
					font-size: 14px;
					display: flex;
					align-items: center;
					font-weight: 400;
					border: #0a0b0d;
					width: 85%;
					border-radius: 6px;
					padding-left: 10px;
					border-bottom-right-radius: 0px;
					border-top-right-radius: 0px;
				}

				.button {
					width: 15%;
					background-color: #1681FF;
					border-top-left-radius: 0px;
					border-bottom-left-radius: 0px;
					border-bottom-right-radius: 6px;
					border-top-right-radius: 6px;
					height: 35px;
					font-size: 14px;
					font-weight: 400;
					align-items: center;
					justify-content: center;
					display: flex;
					margin-top: 0px;
				}
			}
		}

		.terms-and-conditions {
			margin-top: 30px;
			padding: 0px 20px;
			padding-bottom: 30px;
			border-radius: 5px;
			border: 1px solid #DEE1E6;

			.terms-heading {
				color: #71102F;
				font-weight: 600;
				font-size: 16px;
				display: flex;
				gap: 10px;
			}

		}

		.actions {
			margin-top: 20px;
			border: 1px solid #DEE1E6;
			margin-bottom: 50px;
			box-shadow: 3px 3px #171A1F1C;
			padding: 10px 15px;
			border-radius: 3px;
			display: flex;
			justify-content: space-between;

			.action-items {
				display: flex;
				align-items: center;

				.negotiate {
					background-color: white;
					color: #1681FF;
					border: 2px solid #1681FF;
					padding: 10px 15px;
					border-radius: 6px;
					height: 40px;
					display: flex;
					align-items: center;
					justify-content: center
				}

				.agree {
					height: 40px;
				}

			}

			.back-button {
				background-color: white;
				border: none;
				color: #9095A0;
				font-weight: 400;
				font-size: 14px;
			}


		}
	}

	.step2 {
		margin-top: 20px;

		.job-details-section {
			margin-top: 15px;
			padding-top: 5px;
			border-radius: 12px;
			border: 1px solid #BCC1CA;
			background: #FFF;
			box-shadow: 0px 0px 2px 0px rgba(23, 26, 31, 0.12), 0px 0px 1px 0px rgba(23, 26, 31, 0.07);

			.header {
				display: flex;
				gap: 10px;
				justify-content: flex-start;
				padding-left: 10px;
				font-size: 18px;
				color: #171A1F;
				font-weight: 400;

				img {
					height: 30px;
				}
			}

			.form-items {
				margin-left: 50px;
				padding-right: 10px;

				.ant-form-item-label>label {
					color: #424955;
					font-weight: 700;
					font-size: 14px;
				}

				Input {
					background-color: #F3F4F6;
					border-radius: 6px;
					color: #171A1F;
					font-weight: 400;
					font-size: 14px;
				}

				.form-items-2 {
					display: flex;
					gap: 10px;

					.form-items-2-1 {
						min-width: 70%;

						.ant-btn {
							width: 150px;
						}
					}

					.form-items-2-2 {
						min-width: 28%;
					}
				}
			}

		}

		.job-description {
			margin-top: 15px;
			border-radius: 16px;
			border: 1px solid #BCC1CA;
			padding-top: 5px;

			.header {
				display: flex;
				gap: 10px;
				justify-content: flex-start;
				padding-left: 10px;
				font-size: 18px;
				color: #171A1F;
				font-weight: 400;

				img {
					height: 30px;
				}
			}


			.form-items {
				margin-left: 50px;
				padding-right: 10px;

				Input {
					background-color: #F3F4F6;
					border-radius: 6px;
					color: #171A1F;
					font-weight: 400;
					font-size: 14px;
				}
			}

			.ant-form-item-label>label {
				color: #424955;
				font-weight: 700;
				font-size: 14px;
			}

			.form-label {
				color: #424955;
				font-weight: 700;
				font-size: 14px;
			}
		}

		.additional-information {
			margin-top: 15px;
			border-radius: 16px;
			border: 1px solid #BCC1CA;
			padding-top: 5px;

			.header {
				display: flex;
				gap: 10px;
				justify-content: flex-start;
				padding-left: 10px;
				font-size: 18px;
				color: #171A1F;
				font-weight: 400;

				img {
					height: 30px;
				}
			}

			.form-items {
				margin-left: 50px;
				padding-right: 10px;
				display: flex;
				gap: 10px;

				Input {
					/* background-color: #F3F4F6; */
					border-radius: 6px;
					color: #171A1F;
					font-weight: 400;
					font-size: 14px;
				}

				.side-items-1 {
					width: 65%;

					.qualifications {
						width: 100%;
					}

					.width-full {
						width: 100%;
					}
				}

				.side-items-2 {
					width: 33%;

					.qualification-department {
						width: 100%;
					}

					.width-full {
						width: 100%;
					}
				}

			}

			.other-benefits {
				margin-left: 50px;
				padding-right: 60px;
			}

			.ant-form-item-label>label {
				color: #424955;
				font-weight: 700;
				font-size: 14px;
			}

			.form-label {
				color: #424955;
				font-weight: 700;
				font-size: 14px;
			}
		}
	}
}



.companyterms p {
	text-align: left;
}


.footer-btns {
	display: flex;
	justify-content: space-between;
}

.footer-btns button {
	background-color: var(--color-primary);
}

.years-diff {
	gap: 20px;
}


.languages {
	display: flex;
	gap: 10px;
	align-items: center;
}

.languages .language {
	display: flex;
	align-items: center;
	margin-top: 0;

	.language-form-item {
		display: flex;
	}

	.ant-form-item-control-input-content {
		display: flex;
		gap: 5px;
		align-items: center;
	}
}

.job-locations {
	display: flex;
	gap: 10px;
	align-items: center;
}

.job-locations .job-location {
	display: flex;
	align-items: center;
	margin-top: 0;

	.location-form-item {
		display: flex;
	}

	.ant-form-item-control-input-content {
		display: flex;
		gap: 5px;
		align-items: center;
	}
}