.interviewers-container {
  margin-top: 20px;
  padding: 20px;
  background: #ffffff;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  font-size: 1rem;
}

.interviewers-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.interviewers-table th,
.interviewers-table td {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: left;
}

.interviewers-table th {
  background-color: #f4f4f4;
  font-weight: bold;
  text-transform: uppercase;
}

.interviewers-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.interviewers-table tr:hover {
  background-color: #f1f1f1;
}

.no-data {
  text-align: center;
  font-style: italic;
  color: gray;
  margin-top: 10px;
}