.selected {
    background-color: #1890ff;
    color: #fff;
    border-color: #1890ff;
}

.add-rec-btn {
    margin-bottom: 20px;
}

.interviewer-card-client {
    padding: 0px 20px;
    padding-bottom: 20px;
    border-radius: 15px;
    width: 300px;
    background-color: #1681FF0D;

    .profile {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding-top: 10px;
        padding-bottom: 5px;

        .details {
            display: flex;
            gap: 10px;
            padding-top: 5px;
            flex-direction: row;
            align-items: center;
            justify-content: center;

            img {
                height: 50px;
                width: 50px;
            }

            .name {
                display: flex;
                flex-direction: column;

                .interviewer-name {
                    color: #54577A;
                    font-size: 16px;
                    font-weight: 600;
                }

                .joining-date {
                    font-size: 12px;
                    color: #54577A;
                    font-weight: 400;
                }
            }

        }

        .view-all {
            font-weight: 500;
            font-size: 16px;
            color: #54577A;
            cursor: pointer;
        }
    }

    .mail {
        margin-top: 15px;
        margin-left: 10px;
        display: flex;
        align-items: center;
        gap: 8px;
        color: #54577A;
        font-size: 14px;
        font-weight: 400;

        img {
            height: 19px;
        }
    }

    .pending {
        margin-left: 8px;
        margin-top: 15px;
        width: 100%;
        display: flex;
        justify-content: space-between;

        .note {
            display: flex;
            gap: 8px;
            font-weight: 400;
            font-size: 14px;
            color: #54577A;
            align-items: center;
        }
    }
}

.interviewers {
    padding-left: 20px;

    .top-content {
        display: flex;
        gap: 15px;
        justify-content: space-between;
        align-items: center;
        padding: 10px 5px;

        .search-btn {
            display: flex;
            align-items: center;
            border: 1px solid #ccc;
            border-radius: 8px;
            padding: 8px 12px;
            background-color: white;
            width: 80%;

            input {
                border: none;
                outline: none;
                padding: 4px 8px;
                font-size: 14px;
                width: 100%;
            }
        }

        button {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 10px;
            background-color: #1890ff;
            border: none;
            border-radius: 10px;
            color: white;
            cursor: pointer;
            padding: 10px 15px;
        }

        button:hover {
            background-color: #1681FF;
        }

    }

    .title {
        font-size: 24px;
        font-weight: 600;
        color: #171A1F;
        text-align: start;
        padding-top: 10px;
    }

    /* display: none; */
    .interviewer-cards-grid {
        display: none;
        display: grid;
        grid-template-columns: 1fr;
        gap: 20px;
        padding-top: 10px;
    }



    @media screen and (min-width: 768px) {
        .interviewer-cards-grid {
            grid-template-columns: repeat(2, 1fr);
        }
    }

    @media screen and (min-width: 1024px) {
        .interviewer-cards-grid {
            grid-template-columns: repeat(4, 1fr);
        }
    }
}


.interview-card-client {
    background-color: #FFFFFF;
    width: 250px;
    border-left: 10px solid #379AE6 !important;
    box-shadow: 1px 1px 5px rgba(0, 0, 0, 0.5);
    /* border: 2px solid black; */
    border-radius: 15px;
    padding: 20px;

    .line1 {
        color: #379AE6;
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 10px;
    }

    .line2 {
        color: #565E6C;
        font-weight: 400;
        font-size: 14px;
        margin-bottom: 15px;
        display: flex;
        gap: 5px;
        flex-direction: column;

        .time {
            display: flex;
            gap: 10px;
        }
    }

    .line3 {
        color: #565E6C;
        font-weight: 400;
        font-size: 14px;
        margin-bottom: 15px;
        padding-bottom: 15px;
        border-bottom: 1px solid #DEE1E6;
    }

    .line4 {
        display: flex;
        gap: 10px;
        color: #379AE6;
        font-weight: 400;
        font-size: 14px;
    }
}

.scheduled-interviews-client {
    .scheduled-heading {
        margin: 10px 0px;
        padding-bottom: 10px;
        margin-top: 30px;
        font-size: 25px;
        color: #1681FF;
        font-weight: 600;
    }

    padding: 30px;
}