.tabs-container {
    margin: 10px 0px;
    width: 100%;
}

.tabs {
    display: flex;
    /* border-bottom: 2px solid #ddd; */
    justify-content: center;
}

.tab-item {
    padding: 10px 10px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500;
    color: #555;
    transition: 0.3s;
    border-bottom: 3px solid transparent;
}

.tab-item:hover {
    color: #007bff;
}

.active-tab {
    /* background-color: #1890ff; */
    color: #1890ff;
    border-color: #1890ff;
}

.rctr-onproces-card-container .tab-content {
    padding: 20px;
    font-size: 14px;
    width: 100%;
    color: #333;
}