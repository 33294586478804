.interview-card-container {
  width: 400px;
  background: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  padding: 16px;
  font-family: Arial, sans-serif;
}

.interview-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 16px;
  font-weight: bold;
  color: #007bff;
}

.interview-expand-btn {
  background: none;
  border: none;
  font-size: 16px;
  cursor: pointer;
}

.interview-content {
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
}

.interview-left-section, .interview-right-section {
  width: 48%;
}

.interview-info {
  margin-bottom: 10px;
}

.interview-label {
  display: block;
  font-size: 12px;
  color: gray;
}

.interview-value {
  font-size: 14px;
  font-weight: bold;
  color: black;
}

.interview-time {
  display: flex;
  flex-direction: column;
}

.interview-duration {
  background: #fce4ec;
  color: #d81b60;
  padding: 2px 6px;
  border-radius: 4px;
  font-size: 10px;
  display: inline-block;
  margin-bottom: 4px;
  width: 60px;
}

.interview-link {
  color: #007bff;
  text-decoration: none;
}

.interview-interviewer-box {
  display: flex;
  align-items: center;
}

.interview-avatar {
  width: 30px;
  height: 30px;
  border-radius: 50%;
  margin-right: 8px;
}

.interview-footer {
  display: flex;
  justify-content: space-between;
  margin-top: 12px;
}

.interview-edit-btn, .interview-share-btn {
  border: none;
  padding: 6px 12px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.interview-edit-btn {
  background: #f8f9fa;
  color: black;
}

.interview-share-btn {
  background: #007bff;
  color: white;
}
