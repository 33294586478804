.profile-card-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.profile-card-container .profile-card {
  width: 95%;
  /* border: 2px solid #0080ff; */
  border-radius: 8px;
  margin-top: 25px;
  padding: 25px;
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);

  /* margin-right: 20px; */
  display: flex;
  /* position: relative; */
  background-color: white;
  /* justify-content: center; */
  /* align-items: center; */
  /* justify-content: space-between; */

}

.profile-card-container .profile-image-container {
  position: relative;
  margin-right: 50px;

}

.profile-card-container .profile-image {
  width: 130px;
  background-color: lightcoral;
  height: 130px;
  border-radius: 50%;
  object-fit: cover;
}

.profile-card-container .add-button {
  position: relative;
  bottom: 43px;
  left: 90px;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #0080ff;
  border: 2.5px solid white;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.profile-card-container .profile-info {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.profile-card-container .profile-name {
  font-size: 26px;
  font-weight: bold;
  margin: 0 0 5px 0;
  color: #000;
}

.profile-card-container .profile-position {
  font-size: 18px;
  color: #666;
  margin: 0 0 5px 0;
}

.profile-card-container .profile-company {
  font-size: 18px;
  color: #0D7E8D;
  margin: 0 0 5px 0;
}

.profile-card-container .profile-employment-type {
  font-size: 16px;
  color: #666;
  margin: 0;
}

.profile-card-container .edit-button {
  position: relative;
  bottom: 2px;
  left: 580px;
  width: 36px;
  height: 36px;
  border-radius: 50%;
  background-color: #000;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.profile-card-container .add-button svg{
  color: white !important;
}


