.custom-table-container {
    width: 100%;
    overflow-x: auto;
    border-radius: 8px;
    margin: 10px 0;
}

.custom-table {
    width: 100%;
    border-collapse: collapse;
    min-width: 600px;
    margin-top: 15px;
}

.custom-table th,
.custom-table td {
    padding: 12px 16px;
    text-align: left;
    border-bottom: 1px solid #e0e0e0;
}

.custom-table th {
    background-color: #fff;
    color: #A2A1A8;
    font-weight: 300;
    font-size: 16px;
    border-bottom: none;
    margin-bottom: 15px;
}


.custom-table tr:hover {
    background-color: #f9f9f9;
    transition: background-color 0.3s ease;
}

.custom-table td {
    color: #16151C;
}

.no-data {
    text-align: center !important;
    padding: 20px;
    align-items: center;
    justify-content: center;
    color: #888;
    font-weight: 300;
    vertical-align: center;
    height: 150px;
    /* font-size: 16px; */
}

.pagination-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 10px;
    padding: 10px 0;
}

.pagination-container button {
    padding: 5px 10px;
    border: 1px solid #ccc;
    background-color: white;
    cursor: pointer;
    border-radius: 4px;
}

.pagination-container button:disabled {
    opacity: 0.5;
    cursor: not-allowed;
}

.pagination-container select {
    padding: 5px;
    border-radius: 4px;
    border: 1px solid #ccc;
}

/* Optional loader style */
.loader {
    border: 4px solid #f3f3f3;
    border-top: 4px solid #555;
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;
    display: inline-block;
    margin-right: 10px;
}

@keyframes spin {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}


/* Expanded Row Styling */
.expanded-row td {
    background: #f9f9f9;
    padding: 15px;
    border-bottom: 1px solid #e0e0e0;
    border-left: 2px solid #ddd;
}

/* Optional: Expand button styling */
.expand-btn {
    width: 100px;
    background: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 12px;
}