.interviewer-review-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  font-family: Arial, sans-serif;
}

.interviewer-review-card {
  background-color: white;
  border-radius: 8px;
  width: 100%;
  max-width: 800px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  padding: 20px;
}

.interviewer-review-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.interviewer-review-header h2 {
  font-size: 18px;
  font-weight: bold;
  margin: 0;
}

.close-button {
  background: none;
  border: 1px solid #ddd;
  border-radius: 4px;
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  font-size: 14px;
}

.skills-section {
  display: flex;
  border: 1px solid #0099ff;
  border-radius: 4px;
  margin-bottom: 20px;
}

.skills-column {
  flex: 1;
  padding: 15px;
}

.skills-divider {
  width: 1px;
  background-color: #0099ff;
  position: relative;
}

.skills-divider::before {
  content: "";
  position: absolute;
  top: 0;
  left: -2px;
  width: 5px;
  height: 100%;
  background-color: #0099ff;
  opacity: 0.3;
}

.skills-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
  margin-bottom: 10px;
}

.skills-header h3 {
  color: #0099ff;
  font-size: 16px;
  margin: 0;
}

.rate-label {
  font-size: 14px;
  color: #666;
}

.skill-item {
  display: flex;
  justify-content: space-between;
  padding: 8px 0;
  border-bottom: 1px solid #eee;
}

.skill-name {
  color: #333;
}

.skill-rating {
  font-weight: bold;
}

.reviews-section {
  border: 1px solid #eee;
  border-radius: 4px;
}

.reviews-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  border-bottom: 1px solid #eee;
}

.reviews-header h3 {
  color: #0099ff;
  font-size: 16px;
  margin: 0;
}

.summary-label {
  font-size: 14px;
  color: #666;
}

.reviews-list {
  padding: 15px;
}

.review-item {
  margin-bottom: 20px;
}

.review-item h4 {
  font-size: 14px;
  margin: 0 0 8px 0;
  font-weight: bold;
}

.review-item p {
  margin: 0;
  font-size: 14px;
  line-height: 1.5;
  color: #555;
}

