.flex {
	display: flex;
}

*::-webkit-scrollbar {

	display: none;
}

.no_overflow {
	overflow-y: hidden;
	min-width: 100vw;
	min-height: 100vh;
}

.green {
	color: green;
}

.red {
	color: red;
}

.data-overflow {
	width: 86vw;
	max-height: 89vh;
	overflow: auto;
}

button {
	border: none;
	color: white;
	border-radius: 10px;
	font-size: 12px;
	cursor: pointer;
	background-color: #1681FF;
}

.success {
	background-color: #EEFDF3;
	color: #117B34;
}

.pending {
	background-color: #FEF9EE;
	color: #98690C;
}

.failure {
	background-color: #F45B691A;
	color: #F45B69;
}

.hold {
	background-color: #1681FF0D;
	color: #1681FF;
}