.job-details-manager-main {
	padding: 20px;
	background-color: #f9f9f9;
	border-radius: 8px;
	box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
	max-width: 800px;
	margin: auto;
	/* display: flex; */
}

.job-details-manager-main h1 {
	font-size: 24px;
	color: var(--color-primary);
	margin-bottom: 20px;
	text-align: left;
	padding-bottom: 10px;
	border-bottom: 2px solid #3B82F6;
}

.job-details-manager-main .job-detail {
	display: flex;
	/* justify-content: space-evenly; */
	gap: 20px;
	/* align-items: center; */
	background-color: #EFF6FF;
	padding: 20px 20px;
	font-size: 18px;
	font-weight: 600;
	margin: 10px;
}

.job-details-manager-main .job-detail span {
	font-weight: 600;
	color: #1F2937;
	/* flex: 0 0 250px; */
	text-align: left;
	padding-right: 10px;
}

.job-details-container {
	display: flex;
	flex-wrap: wrap;
}



.job-details-manager-main hr {
	border: 1px solid #ddd;
	margin: 20px 0;
}

.job-details-manager-main h2 {
	font-size: 20px;
	margin-bottom: 10px;
	text-align: left;
}

.job-details-manager-main h3 {
	font-size: 18px;
	margin-bottom: 10px;
}

.job-details-manager-main h4 {
	font-size: 16px;
	color: #333;
	margin-bottom: 10px;
}

.job-details-manager-main h4.green {
	color: green;
}

.job-details-manager-main p.red {
	color: red;
	font-weight: bold;
}

.job-details-manager-main .ant-btn {
	margin-right: 10px;
	margin-bottom: 10px;
}

.job-details-manager-main .ant-btn:last-child {
	margin-right: 0;
}

@media (max-width: 768px) {
	.job-details-manager-main {
		padding: 10px;
	}

	.job-details-manager-main h1 {
		font-size: 20px;
	}

	.job-details-manager-main .job-detail {
		flex-direction: column;
		align-items: flex-start;
	}

	.job-details-manager-main .job-detail span {
		text-align: left;
		padding-right: 0;
		margin-bottom: 5px;
	}

	.job-details-manager-main h2,
	.job-details-manager-main h3 {
		font-size: 18px;
	}
}