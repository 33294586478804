.cand-card-container {
  /* background-color: lightpink; */
  width: 100%;
  display: flex;
  margin: 20px auto;
  justify-content: space-between;
  padding: 20px;
  border: 1px solid #BCC1CA;
  border-radius: 10px;
}

.profile-content-container {
  display: flex;
  /* justify-content: ; */
  align-items: center;
}
.profile-content-container .cand-job-status{
  background-color: #F2F2FD;
  color: #1681FF;
  font-size: 12px;
  font-weight: 400;
  border: none;
  outline: none;
  margin: 0;
  padding: 0px;
  border-radius: 15px;
  height: 30px;
  width: 80px;
}

.profile-content-container .agency-cand-profile-div {
  background-color: rgba(135, 140, 237, 1);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50px;
  height:85px;
  padding-top: 0px;
  min-width: 85px;
  margin-right: 20px;

}
.profile-content-container .profile-content-row3{
  width: 100%;
  overflow: hidden;
  font-size: 14px;
  color: #9095A0;
}
.cand-details-button{
  cursor: pointer;
  width: 80px;
    height: 25px;
    background-color: #F2F2FD;
    display: flex;
    gap: 5px;
    border-radius: 4px;
    padding: 5px 10px;
    justify-content: center;
    align-items: center;
    h3{
      margin: 0px;
    border: 0px;
    color: #1681FF;
    font-weight: 400;
    font-size: 15px;
    }
}

.agency-cand-profile-div{

}
.profile-content-container .profile img {}