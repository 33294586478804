.job-card {
    background: #f9f9f9;
    padding: 20px;
    border-radius: 8px;
    margin-bottom: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.job-card h2 {
    color: #333;
    margin-bottom: 10px;
}

.job-card p {
    margin: 5px 0;
    color: #555;
}

.actions {
    margin-top: 15px;
    display: flex;
    gap: 10px;
}

.actions .ant-btn {
    width: 120px;
    font-weight: bold;
}