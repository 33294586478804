.candidate-details-client {
    padding: 10px;

    .main-heading {
        .details {
            color: #171A1F;
            font-size: 24px;
            font-weight: 700;
        }
    }

    .status-bar {
        width: 100%;
        align-items: center;
        justify-content: center;
        border: 2px solid #1681FF;
        ;
        display: flex;

        .box {
            padding: 10px
        }
    }

    .profile-section {
        margin-top: 20px;
        display: flex;
        gap: 10px;
        align-items: center;

        .profile-pic {
            height: 50px;
            width: 50px;
        }

        .name-details {
            .name {
                font-weight: 700;
                font-size: 20px;
                color: #171A1F;
            }

            .features {
                display: flex;
                flex-wrap: wrap;
                gap: 20px;
                align-items: center;

                .feature {
                    display: flex;
                    gap: 5px;
                    color: #171A1F;
                    font-weight: 400;
                    font-size: 14px;
                    margin-top: 0px;
                    padding-top: 0px;
                }
            }

        }
    }

    .information {
        margin-top: 30px;

        .left {
            .information-title {
                color: #1681FF;
                font-size: 14px;
                font-weight: 700;
                padding-bottom: 5px;
                border-bottom: 3px solid #1681FF;
                margin-bottom: 10px;
            }

            .work-experience-details {
                margin-top: 15px;

                .experience-title {
                    display: flex;
                    align-items: center;
                    gap: 15px;
                    color: #171A1F;
                    font-size: 14px;
                    font-weight: 700;

                    .value {
                        border-radius: 10px;
                        background-color: #F3F4F6;
                        color: #323842;
                        padding: 3px 10px;
                    }
                }

                .experiences {
                    margin-top: 15px;
                    margin-left: 20px;
                    border-left: 2px solid #BCC1CA;
                    padding-left: 30px;

                    .experience {
                        margin-top: 20px;

                        .job-role {
                            color: #171A1F;
                            font-size: 14px;
                            font-weight: 700;
                            display: flex;
                            gap: 10px;

                            .status {
                                background-color: #FDF1F5;
                                color: #E8618C;
                                padding: 3px 10px;

                            }

                        }

                        .job-role-details {
                            display: flex;
                            gap: 20px;
                            margin-top: 10px;

                            .details {
                                display: flex;
                                flex-direction: row;
                                gap: 10px;
                                align-items: center;
                                color: #6E7787;
                                font-size: 14px;
                                font-weight: 400;
                            }
                        }
                    }
                }
            }


            .education-details {
                margin-top: 15px;

                .education-title {
                    display: flex;
                    align-items: center;
                    gap: 15px;
                    color: #171A1F;
                    font-size: 14px;
                    font-weight: 700;

                    .value {
                        border-radius: 10px;
                        background-color: #F3F4F6;
                        color: #323842;
                        padding: 3px 10px;
                    }
                }

                .educations {
                    margin-top: 15px;
                    margin-left: 20px;
                    border-left: 2px solid #BCC1CA;
                    padding-left: 30px;

                    .education {
                        margin-top: 20px;

                        .job-role {
                            color: #171A1F;
                            font-size: 14px;
                            font-weight: 700;
                            display: flex;
                            gap: 10px;

                            .status {
                                background-color: #FDF1F5;
                                color: #E8618C;
                                padding: 3px 10px;

                            }

                        }

                        .job-role-details {
                            display: flex;
                            gap: 20px;
                            margin-top: 10px;

                            .details {
                                display: flex;
                                flex-direction: row;
                                gap: 10px;
                                align-items: center;
                                color: #6E7787;
                                font-size: 14px;
                                font-weight: 400;
                            }
                        }
                    }
                }

            }

            .cover-letter {
                margin-top: 15px;

                .cover-letter-head {
                    display: flex;
                    gap: 10px;
                    align-items: center;
                    font-size: 14px;
                    color: #171A1F;
                    font-weight: 700;

                    img {
                        background-color: #F2F5FD;
                        padding: 10px;
                        border-radius: 6px
                    }

                    ;
                    /* background-color: blue; */
                }

                .cover-letter-text {
                    margin-left: 50px;
                    font-size: 12px;
                    color: #6E7787;
                    font-weight: 400;

                }
            }

            .resume {
                margin-top: 25px;

                .resume-head {
                    display: flex;
                    gap: 10px;
                    align-items: center;
                    font-size: 14px;
                    color: #171A1F;
                    font-weight: 700;

                    img {
                        background-color: #F2F5FD;
                        padding: 10px;
                        border-radius: 6px
                    }

                }

                .resume-value {
                    margin-left: 45px;
                    background-color: #FAFAFB;
                    padding: 20px;
                    border-radius: 10px;
                    color: #424955;
                    font-size: 12px;
                    font-weight: 400;
                }
            }

            .comments {
                margin-top: 15px;
                display: flex;
                flex-direction: column;
                gap: 10px;

                .comments-head {
                    display: flex;
                    gap: 10px;
                    align-items: center;
                    font-size: 14px;
                    color: #171A1F;
                    font-weight: 700;

                    img {
                        background-color: #F2F5FD;
                        padding: 10px;
                        border-radius: 6px
                    }

                }

                .comment {
                    margin-left: 45px;
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                    padding: 15px 20px;
                    background-color: #F2F5FD;
                    border-radius: 8px;

                    .interviewer-name {
                        font-size: 14px;
                        font-weight: 700;
                        color: #171A1F;
                    }

                    .txt {
                        font-size: 12px;
                        font-weight: 400;
                        font-weight: 700;
                        color: #6E7787;
                        ;
                    }
                }
            }
        }
    }
}