.skills-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
    padding: 20px;
}

.skills {
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 15px;
    background-color: #f9f9f9;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.skills input,
.skills select {
    width: 100%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

/* Error Messages */
.error {
    color: red;
    font-size: 12px;
    margin-top: 5px;
}

/* Buttons */
/* button {
    padding: 8px 12px;
    border: none;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    border-radius: 5px;
    transition: 0.3s ease;
}

.skills-container button:hover {
    background-color: #0056b3;
} */

.remove-btn {
    background-color: #dc3545;
}

.remove-btn:hover {
    background-color: #b52b3a;
}

/* Responsive Grid */
@media (min-width: 1200px) {
    .skills-container {
        grid-template-columns: repeat(4, 1fr);
    }
}

@media (min-width: 768px) and (max-width: 1199px) {
    .skills-container {
        grid-template-columns: repeat(2, 1fr);
    }
}

@media (max-width: 767px) {
    .skills-container {
        grid-template-columns: repeat(1, 1fr);
    }
}