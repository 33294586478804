.candidate-card {
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  font-family: "Inter", sans-serif;
  border-left: 5px solid rgba(41, 197, 238, 1);
  width: 325px;
  p{
    text-align: left;
  }
}

.candidate-card-header {
  background-color: white;
  padding: 20px;
  padding-bottom: 1px;

  /* border-bottom: 1px solid #e0e0e0; */
}

.candidate-position {
  margin: 0;
  color: #0096ff;
  font-size: 18px;
  font-weight: 600;
}

.candidate-company {
  margin: 5px 0 0;
  color: #555;
 
  font-size: 14px;
}

.candidate-card-content {
  background-color: white;
  padding: 15px;
}

.candidate-info-row {
  display: flex;
  justify-content: space-between;
  margin-bottom: 12px;
}

.candidate-info-item {
  display: flex;
  align-items: center;
  background-color: rgba(22, 129, 255, 0.05);
  padding: 10px 10px;
  border-radius: 50px;
  color: #555;
  font-size: 14px;
}

.candidate-icon {
  margin-right: 6px;
  color: #777;
}

.candidate-actions {
  display: flex;
  /* justify-content: space-between; */
  margin-top: 15px;
}

.candidate-btn {
  font-size: 15px;
  padding: 5px 0;
  width: 65px;
  height: 35px;

  border: none;
  border-radius: 4px;
  font-weight: 400;
  cursor: pointer;
  transition: opacity 0.2s;
}

.candidate-btn:hover {
  opacity: 0.9;
}

.accept-btn {
  background-color: #0ca10c;
  color: white;
}

.reject-btn {
  background-color: #e53935;
  color: white;
}

