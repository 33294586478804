.invoices-table{
  /* background-color: rgba(162, 161, 168, 0.4); */
  border: 1.8px solid #A2A1A866;
  border-radius: 8px;
  padding: 20px 10px;
}
.invoices-table .candidate-table-container {
  padding: 16px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.03);
}

.invoices-table .search-input {
  margin-bottom: 16px;
  border-radius: 4px;
  padding: 8px 12px;
}

.invoices-table .search-icon {
  color: #bfbfbf;
  font-size: 16px;
  margin-right: 8px;
}

.invoices-table .candidate-table .ant-table {
  border: 1px solid #f0f0f0;
  border-radius: 8px;
}

.invoices-table .candidate-table .ant-table-thead > tr > th {
  background-color: #fafafa !important;
  color: #8c8c8c !important;
  font-weight: 500 !important;
  padding: 16px !important;
}

.invoices-table .candidate-table .ant-table-tbody > tr > td {
  padding: 16px !important;
}

.invoices-table .invoice-button {
  display: flex !important;
  align-items: center !important;
  color: red !important;
  cursor: pointer !important;
}

.invoices-table .invoice-icon {
  margin-right: 6px;
  background-color: rgba(243, 249, 255, 1);
}

/* Hover effect for rows */
.invoices-table .candidate-table .ant-table-tbody > tr:hover > td {
  background-color: #f5f5f5 !important;
}