.manager-dashboard-container {
  display: flex;
  margin-top: 0px;
  gap: 20px;
  margin: 15px;
}

.upcomming-meets {
  width: 400px;
  display: flex;
  flex-direction: column;
  text-align: center;
  background-color: rgba(243, 248, 255, 1);
  border-radius: 8px;
  h2{
    text-align: center;
  }

}

.overview-require {
  margin-left: 20px;
  width: 1100px;
}

.overview {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;

  h2 {
    color: var(--color-primary);
  }

  .cards-container {
    display: flex;
    width: 100%;
    flex-wrap: wrap;
    gap: 40px;
  }
}

.require-attention{
  h2{
    margin: 5px;
  }
  h3{
    margin: 5px;
  }
}

.require-attention-table {
  border: 1px solid #071c501a !important;
  color: #071c50 !important;
  width: 100%;
  border-radius: 8px !important;
  border: 2px solid #eee;

}

.interview-cards-container{
  display: flex;
  flex-direction: column;
  align-items: center;


}
.require-attention-table .ant-table-thead>tr>th {
  font-size: 13px !important;
  /* font-weight: bold !important; */
  color: #071c50 !important;
  opacity: 50% !important;
  text-align: center !important;
}

.first-column {
  .tableicon {
    gap: 10px;
    display: flex;

    img {
      width: 40px;
    }
  }
}

.first-column,
.content-column {
  color: #071c50;
  /* Custom text color */
  font-weight: bold;
  /* Bold text */
}

.require-attention-table .ant-table-cell:not(:first-child) {
  color: #071c50;
  /* Replace with your desired text color */
  border-right: none !important;
  /* Removes vertical column borders */
  opacity: 50%;
  text-align: center !important;
}

.require-attention-table .ant-table-cell {
  padding: 11px !important;
  border-right: none !important;
  border-left: none !important;
  margin-right: none !important;
}

.require-attention-table .ant-table-row:hover {
  cursor: pointer !important;
  background-color: #ebf3ff !important;
  /* Light blue background when hovering */
}