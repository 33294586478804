.rctr-assined-jobs{
  width: 95%;
  margin: 0px auto;
}
.rctr-assined-jobs .ant-table{
  
}


.search-date-container {
  display: flex;
  width: 95%;
  /* max-width: 1200px; */
  /* gap: 64px; */
}

.search-input-container,
.date-input-container {  
  display: flex;
flex: 1;
}

.search-input,
.date-input {
  width: 100%;
  padding: 8px 8px 8px 32px;
  border: 1px solid #e2e8f0;
  border-radius: 6px;
  font-size: 14px;
  
  outline: none;
  transition: border-color 0.2s ease;
}

.search-input{

  text-indent: 16px;
}
.search-input:focus,
.date-input:focus {
  border-color: none;
  margin: none;
  box-shadow: 0 0 0 1px rgba(49, 130, 206, 0.2);
}

.date-input::-webkit-calendar-picker-indicator {
  display: none !important;
  /* -webkit-appearance: none; */
}

.search-input-container .search-icon {
  width: 20px !important;
  color: #718096;
  cursor: pointer;
position: relative;
top: 10px;
left: 24px;
}

.search-input::placeholder,
.date-input::placeholder {
  color: #a0aec0;
}

.view-details-btn {
  display: inline-block;
  background-color: #f7fbff; /* Light blue background */
  color: #72b5ff; /* Light blue text */
  font-size: 14px;
  font-weight: 400;
  text-decoration: none;
  padding: 6px 18px;
  border-radius: 8px;
  border: none;
  margin: 0;
  cursor: pointer;
  transition: all 0.3s ease;
}

.view-details-btn:hover {
  background-color: #e8f2ff; /* Slightly darker blue on hover */
}


/* Responsive styles */
@media (max-width: 768px) {
  .search-date-container {
    flex-direction: column;
  }
}

