.calendar-container {
    border: 1px solid #e8e8e8;
    border-radius: 8px;
    overflow: hidden;
    background-color: #fff;
    width: 100%;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.06);
}

.calendar-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 16px 24px;
    border-bottom: 1px solid #f0f0f0;
}

.calendar-navigation {
    display: flex;
    align-items: center;
}

.nav-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 32px;
    height: 32px;
    border-radius: 50%;
    background-color: #f5f5f5;
    margin: 0 4px;
}

.calendar-date {
    margin: 0 12px;
    font-size: 14px;
}

.calendar-body {
    display: flex;
    position: relative;
    height: 540px;
    /* 9 hours * 60px per hour */
}

.time-column {
    width: 80px;
    flex-shrink: 0;
    border-right: 1px solid #f0f0f0;
    background-color: #fafafa;
}

.time-slot {
    height: 60px;
    display: flex;
    align-items: flex-start;
    padding: 0 12px;
    position: relative;
}

.time-label {
    font-size: 12px;
    color: #666;
    position: absolute;
    top: -10px;
}

.events-container {
    flex: 1;
    position: relative;
    overflow: hidden;
}

.grid-line {
    position: absolute;
    left: 0;
    right: 0;
    height: 1px;
    background-color: #f0f0f0;
}

.grid-line:nth-child(1) {
    top: 0;
}

.grid-line:nth-child(2) {
    top: 60px;
}

.grid-line:nth-child(3) {
    top: 120px;
}

.grid-line:nth-child(4) {
    top: 180px;
}

.grid-line:nth-child(5) {
    top: 240px;
}

.grid-line:nth-child(6) {
    top: 300px;
}

.grid-line:nth-child(7) {
    top: 360px;
}

.grid-line:nth-child(8) {
    top: 420px;
}

.grid-line:nth-child(9) {
    top: 480px;
}

.current-time-indicator {
    position: absolute;
    left: 0;
    right: 0;
    height: 2px;
    background-color: #ff4d4f;
    z-index: 10;
}

.current-time-indicator::before {
    content: "";
    position: absolute;
    left: 0;
    top: -4px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #ff4d4f;
}

.current-time-indicator::after {
    content: "";
    position: absolute;
    right: 0;
    top: -4px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #ff4d4f;
}

.event-card {
    position: absolute;
    left: 10px;
    right: 10px;
    border-radius: 8px;
    padding: 0px 12px;
    display: flex;
    align-items: center;
    box-shadow: 0 2px 6px rgba(0, 0, 0, 0.05);
}

.event-success {
    background-color: #f6ffed;
    border: 1px solid #b7eb8f;
}

.event-processing {
    background-color: #e6f7ff;
    border: 1px solid #91d5ff;
}

.event-warning {
    background-color: #fff0f6;
    border: 1px solid #ffadd2;
}

.event-default {
    background-color: #f5f5f5;
    border: 1px solid #d9d9d9;
}

.event-icon {
    margin-right: 12px;
    font-size: 20px;
    color: #1890ff;
}

.event-content {
    flex: 1;
}

.event-title {
    font-weight: 500;
    margin-bottom: 4px;
}

.event-time {
    font-size: 12px;
    color: #666;
}

.event-actions {
    display: flex;
    gap: 8px;
}

.join-button {
    background-color: #1890ff;
    border-color: #1890ff;
}

.reschedule-button {
    background-color: #fff;
    border-color: #d9d9d9;
}

@media (max-width: 768px) {
    .event-actions {
        flex-direction: column;
    }

    .event-card {
        flex-direction: column;
        align-items: flex-start;
    }

    .event-icon {
        margin-bottom: 8px;
        margin-right: 0;
    }

    .event-content {
        margin-bottom: 8px;
        width: 100%;
    }

    .event-actions {
        width: 100%;
    }
}