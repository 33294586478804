.job-resp-card-container{
  display: flex;
  background-color: hsl(220, 14%, 96%);
  min-width: 185px;
  border-radius: 8px;

/* padding:4px 20px ; */
  justify-content:center;
  gap: 50px;
  
  .left-container{
    display: flex;
gap: 20px;
align-items: center;
    
  }
}
.left-container{
  display: flex;
  gap: 4px;
}
.left-cont-text{
  display: flex;
  flex-direction: column;
  h5{
    margin: 5px;
    font-size: 14px;
    font-weight: 500px;
    padding: 0px;
  }
}