.about-us {
    .section1 {
        color: #4D5163;

        .line1 {
            text-align: center;
            margin-top: 20px;
            font-size: 48px;
            font-weight: 600;
        }

        .line2 {
            /* width: 75%; */
            text-align: center;
            margin-top: 10px;
            padding-bottom: 50px;
            font-size: 18px;
        }
    }

    .our-story {
        margin-top: 20px;
        display: flex;
        gap: 20px;
        padding: 10px 60px;

        .content {

            display: flex;
            flex-direction: column;
        }

        .name {
            color: #565E6C;
            /* font-family: Inter; */
            font-size: 36px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
        }

        .text {
            margin-top: 10px;
            color: #565E6C;
            /* font-family: Inter; */
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }

    }

    .mission-vision {
        padding: 25px 60px;
        display: flex;
        gap: 20px;

        .mission,
        .vision {
            padding: 28px 26px;
            display: flex;
            flex-direction: column;
            gap: 10px;
            border-radius: 12px;
            background: #FFF;
            box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
            backdrop-filter: blur(100px);

            .name {
                display: flex;
                align-items: center;
                gap: 10px;
                color: rgba(88, 161, 255, 0.59);
                /* font-family: Lato; */
                font-size: 24px;
                font-style: normal;
                font-weight: 500;
                line-height: normal;
            }

            .text {
                color: #727272;
                /* font-family: Inter; */
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
        }
    }

    .section2 {
        /* border: 2px solid black; */
        padding-top: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .matter {
            width: 30vw;
            color: #4D5163;
            text-align: center;
            font-size: 45px;
            font-style: normal;
            font-weight: 600;

            .fifty {
                color: #F46EBE;
            }
        }

        .features {
            img {
                /* height: 500px;
                width: 500px; */
            }
        }

        .subscribers {
            margin-top: 10px;
            display: flex;
            padding: 40px;
            width: 80%;
            justify-content: space-between;

            .counter {
                display: flex;
                flex-direction: column;
                /* justify-content: space-between; */

                .count {
                    font-size: 70px;
                    color: #4394DF;
                    font-weight: 600;
                }

                .about {
                    color: #4D5163;
                    font-weight: 400;
                    font-size: 14px;
                }
            }
        }
    }

    .section3 {
        margin-top: 8vh;
        padding-bottom: 8vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .name {
            width: 40%;
            color: #4D5163;
            text-align: center;
            /* font-family: "Playfair Display"; */
            font-size: 48px;
            font-style: normal;
            font-weight: 600;
            line-height: 64px;
            /* 114.286% */
            letter-spacing: -1.68px;

            .pink {
                color: #F46EBE;
            }
        }

        .text {
            width: 40%;
            color: #4D5163;
            text-align: center;
            /* font-family: Inter; */
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }

        .team {
            width: 100%;
        }

    }

    .section4 {
        padding-top: 30px;
        padding-bottom: 30px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .name {
            color: #4D5163;
            /* font-family: "Playfair Display"; */
            font-size: 48px;
            font-style: normal;
            font-weight: 600;
            line-height: 64px;
            letter-spacing: -1.68px;

            .pink {
                color: #F46EBE;
            }
        }

        .text {
            color: #4D5163;
            text-align: center;
            /* font-family: Inter; */
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }

        .itemss {

            margin-top: 45px;
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            gap: 25px;

            .item {
                width: 40%;
                padding: 10px;
                border-radius: 12px;
                background: #FFF;
                box-shadow: 0px 0px 16px 0px rgba(77, 104, 195, 0.12);
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                img {

                    border-radius: 8px;
                    background: #58A1FF;
                    padding: 10px;
                    height: 35px;
                    width: 35px;
                    margin-bottom: 10px;
                }

                .name {
                    color: #56A8FD;
                    /* font-family: Lato; */
                    font-size: 20px;
                    /* font-style: normal; */
                    font-weight: 500;
                    line-height: normal;
                }

                .description {
                    text-align: start;
                    overflow: hidden;
                    color: #565E6C;
                    text-overflow: ellipsis;
                    /* font-family: Lato; */
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }
            }
        }

    }
}

.team-carousel {
    position: relative;
    width: 100%;
    max-width: 1200px;
    margin: 0 auto;
    padding: 40px 0;
    overflow: hidden;
}

.carousel-container {
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    height: 400px;
}

.team-member {
    position: absolute;
    will-change: transform;
}

.member-image-container {
    position: relative;
    width: 300px;
    height: 350px;
    border-radius: 8px;
    overflow: hidden;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.member-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.member-info {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 15px;
    background: rgba(0, 0, 0, 0.6);
    color: white;
    text-align: center;
    border-radius: 0 0 8px 8px;
}

.member-info h3 {
    margin: 0;
    font-size: 18px;
    font-weight: 600;
}

.member-info p {
    margin: 5px 0 0;
    font-size: 14px;
    opacity: 0.9;
}

.carousel-controls {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin-top: 20px;
}

.nav-button {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    height: 50px;
    border: 2px solid #3498db;
    border-radius: 50%;
    background: white;
    color: #3498db;
    cursor: pointer;
    transition: all 0.3s ease;
    outline: none;
}

.nav-button:hover {
    background: #3498db;
    color: white;
}