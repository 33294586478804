.reopen-jobs-list {
    max-width: '80%';
    margin: 20px auto;
    padding: 20px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.reopen-jobs-list .reopen-heading {
    text-align: center;
    font-size: 24px;
    color: #333;
    margin-bottom: 20px;
}

.reopen-jobs-list .job-list {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.reopen-jobs-list .job-card {
    padding: 15px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background: #f9f9f9;
    transition: transform 0.2s ease-in-out;
}

.reopen-jobs-list .job-card:hover {
    transform: scale(1.02);
}

.reopen-jobs-list .job-card h3 {
    margin: 0;
    font-size: 18px;
    color: #222;
}

.reopen-jobs-list .job-card p {
    margin: 5px 0;
    color: #555;
}

.reopen-jobs-list .renew-btn {
    background: #007bff;
    color: #fff;
    border: none;
    padding: 8px 12px;
    border-radius: 5px;
    cursor: pointer;
    margin-top: 10px;
    font-size: 14px;
}

.reopen-jobs-list .renew-btn:hover {
    background: #0056b3;
}

.reopen-jobs-list .no-data {
    text-align: center;
    color: #888;
    font-size: 16px;
}