.interviewer-details {
    padding: 10px;

    .heading {
        display: flex;
        align-items: flex-start;
        font-size: 32px;
        font-weight: 600;
        color: #171A1F;
    }

    .profile {
        display: flex;
        width: 100%;
        background-color: #1681FF0D;
        align-items: center;
        padding: 20px 20px;
        border-radius: 10px;
        gap: 10px;

        /* justify-content: center; */
        .note {
            display: flex;
            justify-content: center;
            gap: 10px;
            align-items: center;
            font-weight: 400;
            font-size: 16px;
            color: #54577A;
            padding-left: 20px;
        }
    }

}

.table-component {
    border: 1px solid #A2A1A833;
    margin: 10px 30px;
    padding-top: 10px;
    border-radius: 10px;

    .search-option {
        margin: 10px 15px;
        padding: 10px 10px;
        display: flex;
        border: 1px solid #A2A1A866;
        color: #16151C;
        border-radius: 10px;
        gap: 5px;
        font-size: 16px;

        input {
            outline: none;
            border: none;
            background-color: white;
            color: #16151C;
            font-size: 16px;
        }
    }

    .table {
        margin: 10px 15px;

        .custom-table thead {
            border-width: 0px 0px 1px 0px;
        }

        .custom-table thead th {
            font-size: 16px;
            font-weight: 300;
            color: #A2A1A8;
            border-top: none;
        }
    }
}