.footer {
    background-color: #071C50;
    padding-top: 50px;
    color: white;

    .line1 {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 48px;
        font-weight: 600;
        margin-bottom: 80px;

        .pink {
            color: #F46EBE;
        }

        .button button {
            color: black;
            padding: 10px 20px;
            font-size: 14px;
            border-radius: 999px;
            background: #FFF;
        }

        .button {
            padding-bottom: 80px;
        }

        .small-line {
            width: 80%;
            border-bottom: 2px solid rgba(255, 255, 255, 0.16);
        }
    }


    .section2 {
        /* background-color: white; */
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding-bottom: 10vh;
        align-items: flex-start;

        .social-media {
            display: flex;
            flex-direction: column;
            padding-left: 5vw;
            align-items: flex-start;
            /* padding: 32px 0px 96px 0px; */
            /* gap: 6px; */

            img {
                display: flex;
                width: 288px;
                /* padding-left: 0; */
                flex-direction: column;
                flex-shrink: 0;
            }

            .links {
                display: flex;
                gap: 15px;
                padding-left: 64px;
                font-size: 24px;
            }
        }

        .menuu {
            display: flex;
            gap: 70px;
            justify-content: space-between;
            padding-right: 10vw;

            .menu-items {
                .name {
                    color: #FFF;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    /* padding-bottom: 15px; */
                    line-height: 24px;
                }

                .list {
                    color: rgba(255, 255, 255, 0.80);
                    /* font-family: Inter; */
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 24px;
                    /* 171.429% */
                    margin-top: 15px;
                    display: flex;
                    flex-direction: column;
                    gap: 10px;
                }
            }
        }
    }

    .section3 {
        padding: 0 8vw;

        .lang {
            color: #FFF;
            /* font-family: Inter; */
            font-size: 14px;
            font-style: normal;
            font-weight: 500;
            line-height: 16px;
            /* 114.286% */
            padding-bottom: 30px;
            margin-bottom: 30px;
            border-bottom: 2px solid rgba(255, 255, 255, 0.16);
        }

        .last {
            display: flex;
            justify-content: space-between;
            padding-bottom: 30px;
            color: #FAF4EE;
            /* font-family: Inter; */
            font-size: 12px;
            font-style: normal;
            font-weight: 400;
            line-height: 18px;
            /* 150% */

            .list {
                display: flex;
                gap: 20px;
                color: rgba(255, 255, 255, 0.70);
                /* font-family: Inter; */
                font-size: 12px;
                font-style: normal;
                font-weight: 400;
                line-height: 18px;
                /* 150% */
            }
        }
    }
}