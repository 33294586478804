.container {
    max-width: 800px;
    margin: 20px auto;
    padding: 20px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.heading {
    text-align: center;
    font-size: 24px;
    color: #333;
    margin-bottom: 20px;
}

.job-form {
    display: flex;
    flex-direction: column;
}

.ant-form-item {
    margin-bottom: 16px;
}

.ant-input,
.ant-select {
    width: 100%;
}

/* button {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
}

.container button:hover {
    background-color: #0056b3;
} */


.interviewer-container {
    margin-top: 10px;
    padding: 15px;
    border-radius: 8px;
    background: #f9f9f9;
    display: flex;
    gap: 15px;
}

.interviewer-title {
    font-size: 18px;
    margin-top: 10px;
    font-weight: bold;
    margin-bottom: 0px;
}

.add-interviewer-btn {
    margin-top: 15px;
    color: white;
    border: none;
    display: flex;
    align-items: center;
    gap: 8px;
    width: 150px;
    margin-bottom: 20px;
    margin-left: 30px;
}

.remove-btn {
    margin-top: 10px;
    background: #ff4d4f;
    color: white;
    border: none;
}

.interviewer-card {
    background: #f9f9f9;
    padding: 15px;
    border-radius: 8px;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.add-interviewer-btn {
    margin-top: 10px;
}