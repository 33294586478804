.custom-datepicker .ant-picker-dropdown {
    min-width: 1000px !important;
    /* Adjust as needed */
}

.ant-picker-dropdown {
    overflow: visible !important;
    width: auto !important;
    max-width: 20px !important;
    /* Adjust width */
}