.custom-search {
    display: flex;
    margin-top: 20px;
    padding-left: 15px;
    border-radius: 10px;
    border: 1px solid #A2A1A866;
    outline: none;
    color: rgba(22, 21, 28, 1);
    font-size: 14px;
    font-weight: 300;
    align-items: center;
    height: 55px;
    gap: 10px;

    input {
        border: none;
        outline: none;
        color: rgba(22, 21, 28, 1);
    }
}