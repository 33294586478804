.all-recruiters {
    padding: 20px;
    padding-top: 0px;
    /* background: #f9f9f9; */
}

.header {
    display: flex;
    justify-content: space-between;
    margin: 20px;
}

.search {
    background-color: rgba(234, 236, 240, 1);
    padding: 3px 15px;
    border-radius: 5px;
    margin-bottom: 20px;
}

.add {
    height: 40px;
    border: none;
    border-radius: 5px;
    background-color: rgba(22, 129, 255, 1);
    color: rgba(255, 255, 255, 1);
    cursor: pointer;
}

.search input {
    width: 300px;
    padding: 8px;
    border: none;
    border-radius: 4px;
    background-color: transparent;
    outline: none;
}

.recruiters-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
}

.all-recruiters .box {
    background: white;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: center;
    color: rgba(23, 26, 31, 1);
    text-align: center;
}

.image {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background: #f1f1f1;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}

.image img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 50%;
}

.default-icon {
    font-size: 30px;
    color: gray;
}

.name {
    font-size: 18px;
    font-weight: 400;
    margin-top: 10px;
}

.phone {
    margin-top: 10px;
    color: rgba(23, 26, 31, 1);
    font-weight: 400;
    font-size: 12px;
}

.email {
    font-size: 12px;
    color: rgba(144, 149, 161, 1);
    display: flex;
    align-items: center;
    gap: 5px;
    margin-top: 5px;
    /* margin-top: 5px; */
}

.view-profile {
    margin-top: 10px;
    padding: 6px 12px;
    border: none;
    background: #007bff;
    color: white;
    border-radius: 4px;
    cursor: pointer;
    transition: 0.3s;
}

.view-profile:hover {
    background: #0056b3;
}