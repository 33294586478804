.complete-application {
    .line1 {
        padding: 10px;
        display: flex;
        justify-content: space-between;

        .name-details {
            display: flex;
            flex-direction: column;

            .cand-name {
                font-size: 16px;
                color: #54577A;
                font-weight: 600;
            }

            .dob {
                font-size: 12;
                font-weight: 400;
                color: #54577A;
            }
        }

        .view-resume {
            color: #117B34;
            font-size: 12px;
            font-weight: 400;
            background-color: #EEFDF3;
            border-radius: 14px;
            align-items: center;
            justify-content: center;
            display: flex;
            padding: 3px 15px;
            cursor: pointer;
        }
    }

    .line2 {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;
        margin-top: 5px;
        padding-bottom: 10px;
        margin-bottom: 10px;
        border-bottom: 1px solid #54666929;

        .component {
            background-color: #1681FF0D;
            color: #555555;
            display: flex;
            align-items: center;
            gap: 5px;
            padding: 8px 10px;
            border-radius: 30px;
        }
    }

    .line3 {
        .skills {
            margin-bottom: 15px;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            gap: 10px;

            .skill {
                padding: 10px 10px;
                display: flex;
                flex-direction: column;

                .skill-name {
                    font-size: 14px;
                    color: #171A1F;
                    font-weight: 700;
                }
            }
        }

        border-bottom: 1px solid #54666929;
        margin-bottom: 10px;
    }

    .buttons {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .reject {
            background-color: #E8618C1A;
            color: #E8618C;
            height: 40px;
            padding: 5px 10px;
            border-radius: 4px;
            font-size: 12px;
            font-weight: 300;

        }

        .accept {
            background-color: #1681FF0D;
            color: #1681FF;
            height: 40px;
            padding: 5px 10px;
            border-radius: 4px;
            font-size: 12px;
            font-weight: 300;
        }
    }
}