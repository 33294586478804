.rctr-summery-card-container{
  width: 100%;
  padding: 16px 0px;
  display: flex;
  flex-wrap: wrap;
  gap: 16px;
  justify-content: center;
}
.rctr-summery-card-container .stat-card {
  position: relative;
  padding: 5px 10px;
  border-radius: 10px; 
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  backdrop-filter: blur(3px);
  border: 1px solid rgba(255, 255, 255, 0.1);
  overflow: hidden;
  flex: 1;
  min-width: 110px; /* Reduced minimum width */
  max-width: 160px; /* Reduced maximum width */
}

.rctr-summery-card-container .stat-card:hover {
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  transform: translateY(-3px);
}

.rctr-summery-card-container .stat-card-content {
  display: flex;
  flex-direction: column;
}

.rctr-summery-card-container .stat-card-header {
  display: flex;
  gap:10px;
  margin-bottom: 2px; /* Reduced margin */
}

.rctr-summery-card-container .stat-icon {
  font-size: 20px; /* Reduced icon size */
  opacity: 0.9;
}

.rctr-summery-card-container .stat-value {
  font-size: 18px; /* Reduced font size */
  font-weight: 600;
  margin: 0 4px;
  letter-spacing: -0.5px;
}

.rctr-summery-card-container .stat-label {
  font-size: 12px; 
  font-weight: 500;
  opacity: 0.8;
  text-align: left;
  margin-top: 2px; 
  margin-bottom:0px ;
}

.rctr-summery-card-container .bg-card-purple {
  background-color: #F5F2FD;
  border: 1px solid #BBA4EE;
}

.rctr-summery-card-container .bg-card-blue {
  background-color: #e0f2fe;
  border: 1px solid #1681FF0D;
}


.rctr-summery-card-container .bg-card-pink {
  background-color: #FDF1F5;
  border: 1px solid #FBE0E8;
}

.rctr-summery-card-container .text-card-pink-text {
  color: #db2777;
}

.rctr-summery-card-container .bg-card-green {
  background-color: #EFFCFA;
  border: 1px solid #BAF3EB;
}



.rctr-summery-card-container .bg-card-violet {
  background-color: #f3e8ff;
}

.rctr-summery-card-container .text-card-violet-text {
  color: #7e22ce;
}

/* Animation for card entrance */
@keyframes fadeIn {
  0% {
    opacity: 0;
    transform: translateY(8px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.animate-fade-in {
  animation: fadeIn 0.2s ease-out forwards;
}
