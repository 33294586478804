.custom-table .ant-table-thead>tr>th {
    background-color: #FAFAFB;
    color: #565E6C;
    font-weight: bold;
    /* text-align: center; */
    font-size: 14px;
    font-weight: 500;
    padding: 16px;
    border-bottom: 2px solid #fff;
}

.search {
    background-color: #F3F4F6;
}

.sec-1 {
    .buttons {
        display: flex;
        gap: 15px;
    }
}