/* Container for scrolling if data overflows */
.data-overflow {
  margin: 20px;
  border: 1px solid #ddd;
  border-radius: 8px;
}

table {
  width: 100%;
  border-collapse: collapse;
  min-width: 800px;
  margin-top: 20px;
}

thead {
  background-color: #FAFAFB;
  border-width: 1px 0px 1px 0px;

  border-style: solid;

  border-color: #F3F4F6;

}

thead th {
  padding: 16px 16px;
  text-align: left;
  font-weight: 600;
  font-size: 14px;
  color: #565E6C;
}

tbody td {
  padding: 10px 16px;
  border-bottom: 1px solid #eee;
  color: #171A1F;
  font-size: 14px;
  font-weight: 400;
  white-space: nowrap;
}

tbody tr:hover {
  background-color: #f9f9f9;
  transition: background-color 0.3s ease;
}

td>.view-resumes {
  padding: 2px 10px;
  background-color: #1681FF0D;
  color: #1681FF;
  border: none;
  height: 40px;
  border-radius: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 14px;
}

.view-resumes:hover {
  background-color: #40a9ff;
}

.no-applications {
  padding: 20px;
  text-align: center;
  color: #565E6C;
  font-size: 16px;
  font-weight: 500;
}

/* Responsive Design */
@media screen and (max-width: 768px) {

  thead th,
  tbody td {
    padding: 10px;
    font-size: 14px;
  }

  button {
    padding: 4px 8px;
    font-size: 12px;
  }
}

.received-container {
  .header-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .search {
      width: 40%;
      background-color: white;
      padding: 0;
      margin: 0;
    }

    .sort {
      padding: 0;
      margin: 0;
    }
  }
}