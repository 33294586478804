.job-details-container {
  /* width: 100%; */
  margin: 0px auto;
  padding: 20px;
  background: #ffffff;
  /* box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); */
  border-radius: 8px;
  font-size: 1vw;
  border: 1px solid #dee1e6;
  position: relative;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.job-details-container h1 {
  text-align: center;
  /* font-size: 24px; */
  margin-bottom: 20px;
}

.job-detail {
  display: flex;
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  padding: 10px;
  padding-right: 60px;
  padding-left: 20px;
  height: 30px;

  h2 {
    font-size: 14px;
    color: #323842;
    font-weight: 400;
    margin: 0px;
    min-width: 35%;
  }

  h3 {
    font-size: 14px;
    color: #323842;
    font-weight: 700;
    width: 65%;
  }
}

.job-detail span {
  font-weight: bold;
  min-width: 250px;
  text-align: left;
}

.job-detail div {
  flex-grow: 1;
  text-align: left;
}

hr {
  margin: 20px 0;
  border: 0.5px solid #ddd;
}