.blogs-container {
    padding: 20px;
    display: flex;
    flex-direction: column;

    .blog-top {
        display: flex;
        justify-content: space-between;

        .buttons {
            display: flex;
            gap: 10px;
        }
    }

    .blogs-header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;

        h1 {
            margin: 0;
            font-size: 24px;
        }

        button {
            font-size: 16px;
        }
    }

    .blogs-list {
        display: flex;
        flex-wrap: wrap;
        gap: 20px;

        .blog-card {
            width: 300px;
            box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
            transition: transform 0.3s;

            &:hover {
                transform: scale(1.05);
            }

            img {
                height: 180px;
                object-fit: cover;
            }
        }
    }
}