.Rctr-sum-interview-card {
  display: flex;
  align-items: center;
  background: #f9f9f9;
  padding: 5px ;
  border-radius: 10px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  width: 300px;
  gap: 10px;
}

.Rctr-sum-interview-card .time-section {
  text-align: center;
  margin-right: 10px;
  padding: 11px;
}

.Rctr-sum-interview-card .time {
  font-size: 18px;
  font-weight: bold;
  color: #333;
}

.Rctr-sum-interview-card .interview {
  color: #e91e63;
  font-size: 14px;
  
}
.Rctr-sum-interview-card .interview p{
  margin: 0px ;
  padding:0px;
}


.Rctr-sum-interview-card .divider {
  width: 2px;
  background: #e91e63;
  height: 50px;
  margin-right: 10px;
}

.Rctr-sum-interview-card .details-section {
  
  align-items: center;
  h1{
    text-align: left;
  }
}

.Rctr-sum-interview-card .name {
  font-weight: bold;
  font-size: 16px;
  margin: 3px;
  margin-bottom: 1px;
}

.Rctr-sum-interview-card .info {
  font-size: 14px;
  color: #555;
}

.Rctr-sum-interview-card .detail {
  display: flex;
  align-items: center;
  gap: 5px;
  margin: 0px;
}

.Rctr-sum-interview-card .detail p{
  padding: 0px;
  margin: 0px;
}
.Rctr-sum-interview-card .rctr-sum-int-card{
  display: flex;
  flex-direction: column;
}
