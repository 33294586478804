.file-upload-container {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
    "Helvetica Neue", sans-serif;
  /* max-width: 350px; */
  margin: 0 auto;
  padding: 20px;
  display: flex;
  gap: 10px;
  /* width: 300px; */
}

.upload-card {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  padding: 20px;
  margin-bottom: 20px;
  width: 305px;
}

.section-title {
  color: #4a5568;
  font-size: 18px;
  font-weight: 600;
  margin: 0 0 15px 0;
}

.drop-area {
  border: 2px dashed #4299e1;
  border-radius: 5px;
  padding: 25px;
  text-align: center;
  position: relative;
  cursor: pointer;
  margin-bottom: 15px;
}

.upload-icon {
  width: 40px;
  height: 40px;
  margin: 0 auto 10px;
  color: #4299e1;
}

.upload-text {
  color: #a0aec0;
  margin: 5px 0;
  font-size: 14px;
}

.file-input {
  position: absolute;
  top: 0;
  left: 0;
  /* width: 100%; */
  /* height: 100%; */
  opacity: 0;
  cursor: pointer;
}

.file-info {
  display: flex;
  align-items: center;
  border: 1px solid #e2e8f0;
  border-radius: 5px;
  padding: 10px;
  background-color: #f7fafc;
}

.file-info-compact {
  flex: 1;
  margin-right: 10px;
}

.file-icon {
  width: 24px;
  height: 24px;
  color: #2b6cb0;
  margin-right: 10px;
}

.file-details {
  display: flex;
  flex-direction: column;
}

.file-name {
  font-size: 14px;
  color: #2d3748;
}

.file-size {
  font-size: 12px;
  color: #718096;
}

.file-action-row {
  display: flex;
  align-items: center;
}

.share-button {
  background-color: #3182ce;
  color: white;
  border: none;
  border-radius: 5px;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
}

.share-button:hover {
  background-color: #2b6cb0;
}

