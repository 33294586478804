.applications-container {
    /* max-width: 800px; */
    width: 94%;
    margin: 20px auto;
    padding: 20px;
    display: flex;
    flex-direction: column;
    border: 1px solid #DEE1E6;
    border-radius: 8px;
}

.applications-container>h2 {
    text-align: center;
    margin-bottom: 20px;
}

.applications-container>.cards-container {
    display: flex;
    flex-direction: column;
    max-height: 500px;
    /* Adjust height to enable scrolling */
    overflow-y: auto;
    padding-right: 10px;
    /* Space for scrollbar */
}

.cards-container::-webkit-scrollbar {
    width: 5px;
}

.applications-container>.cards-container>.card {
    display: flex;
    justify-content: space-between;
    align-items: center;    
    flex-direction: row;
    background: #fff;
    padding: 10px 15px;
    border-radius: 15px;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
    cursor: grab;
    transition: transform 0.2s ease;
    min-height: 20px;
    height: 40px;
    border-left: 8px solid #7A9CE4;
    
    /* Reduced card height */
}

.applications-container>.cards-container>.card:active {
    transform: scale(1.02);
}

.applications-container>.cards-container>.card h3 {
    font-size: 16px;
    font-weight: bold;
    margin-right: 5px;
}

.applications-container>.cards-container>.card p {
    font-size: 14px;
    margin: 0;
}

/* Flexbox for same-line arrangement */
.applications-container>.cards-container>.card-content {
    display: flex;
    align-items: center;
    gap: 15px;
}

/* Status-based color changes */
.status {
    font-weight: 500;
    padding: 5px 10px;
    border-radius: 5px;
}

/* Colors for different statuses */
.status.processing,
.status.selected {
    color: #28a745;
    /* background: none; */
    background-color: #F5FFF3 ;
}

.status.pending {
    /* color: #fff; */
    color: #7EB9FF;
    background-color: #F3F9FF;
}

.status.rejected,
.status.left {
    /* color: #fff; */
   color: #dc3545;
   background-color: #fff0f0;
}


/* Pagination buttons */
.applications-container>.pagination-buttons {
    display: flex;
    justify-content: center;
    gap: 10px;
    margin-top: 20px;
}

.applications-container>.pagination-buttons button {
    padding: 8px 12px;
    font-size: 14px;
}