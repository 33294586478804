.add-interviewer {
    width: 100%;

    .title {
        margin-top: 20px;
        padding-bottom: 10px;
        display: flex;
        gap: 10px;
        align-items: center;
        justify-content: flex-start;
        border-bottom: 1px solid #A2A1A866;
        font-weight: 600;
        font-size: 16px;
        color: #1681FF;
    }

    .add-rec-form {
        width: 100%;
        margin: auto;
        padding: 24px;
        display: flex;
        flex-direction: column;
        gap: 16px;
        border-radius: 10px;

        .ant-input {
            height: 45px;
            padding: 10px;
            border-radius: 8px;
            font-size: 15px;
            transition: all 0.3s ease;
        }

        .ant-input:focus,
        .ant-input-focused {
            border-color: d9d9d9;
            box-shadow: 0 0 0 2px rgba(22, 119, 255, 0.2);
        }

    }

    .button-end {
        display: flex;
        align-items: flex-end;
        width: 100%;

        button {
            background-color: #1681FF;
            padding: 10px 20px;
            border: none;
            border-radius: 10px;
            color: white;
            cursor: pointer;
        }

        button:hover {
            scale: 1.01;
        }
    }


}