.compare-container {
    padding: 20px;
}

.compare-table-wrapper {
    overflow-x: auto;
}

.compare-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
    background-color: #fff;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.compare-table th,
.compare-table td {
    border: 1px solid #ccc;
    padding: 12px;
    text-align: center;
    vertical-align: middle;
}

.compare-table th {
    background-color: #f4f4f4;
    width: 200px;
    text-align: left;
    font-weight: bold;
}

.compare-table td a {
    color: blue;
    text-decoration: underline;
}

.action-buttons {
    display: flex;
    gap: 6px;
    justify-content: center;
}

.action-buttons button {
    padding: 6px 10px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    background-color: #007bff;
    color: #fff;
    transition: background-color 0.3s ease;
}

.action-buttons button:hover {
    background-color: #0056b3;
}

.remove-compare-btn {
    margin-top: 5px;
    background-color: #ff4d4f;
    color: white;
    border: none;
    padding: 4px 8px;
    cursor: pointer;
    border-radius: 4px;
    font-size: 12px;
}

.remove-compare-btn:hover {
    background-color: #d9363e;
}

.compare-container {
    padding: 20px;

    .job-post-header {
        padding: 5px;

        .job-post-details {
            display: flex;
            justify-content: space-between;

            .left-side {
                display: flex;
                align-items: center;
                gap: 10px;
                justify-content: center;
                font-size: 22px;
                font-weight: 400;
                color: #171A1F;
            }

            .cart {
                button {
                    background-color: #1681FF;
                    border-radius: 6px;
                    height: 40px;
                    padding: 5px 15px;
                }
            }
        }

        .ctc-details {
            margin-top: 10px;
            display: flex;
            gap: 20px;

            .ctc {
                display: flex;
                align-items: center;
                gap: 10px;
                font-size: 14px;
                font-weight: 700;
                color: #282E38;
            }

            .job_title {
                font-size: 14px;
                font-weight: 700;
                color: #282E38;
                display: flex;
                align-items: center;
                gap: 10px;

                img {
                    height: 28px;
                    width: 28px;
                    color: black
                }

                ;
            }
        }

        .job-description {
            margin-top: 10px;
            width: 80%;
            color: #6E7787;
            font-size: 14px;
            font-weight: 400;
        }
    }
}