.cand-upcoming-interviews .ant-table{
  box-shadow: 0px 0px 4px rgba(0, 0, 0, 0.25);

}
.cand-upcoming-interviews .ant-table-thead > tr > th {
  color: #565E6C !important; 
  text-align: center !important; 
}

.cand-upcoming-interviews .ant-table-tbody > tr > td {
  text-align: center !important; 
}

.cand-upcoming-interviews .ant-table-tbody > tr:nth-child(odd) {
  background-color: #F6FAFF !important; 
}
/*
.cand-upcoming-interviews .ant-table .ant-table-tbody > tr:hover {
  transition: background-color 10 ease-in-out; 
  background-color: rgb(255, 0, 0) !important; #FFF1F1 Light red background on hover
}
   */