.events-container {
  width: 95%;
  /* max-width: 900px; */
  background: white;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
}

.events-container .events-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: bold;
  margin-bottom: 20px;
}

.events-container .calendar-icon {
  color: #007bff;
  margin-right: 5px;
}

.events-container .events-title {
  color: #007bff;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  align-items: center;
}

.events-container .navigation {
  display: flex;
  align-items: center;
  gap: 10px;
}

.events-container .nav-btn {
  background: none;
  border: none;
  cursor: pointer;
  font-size: 14px;
  color: #333;
}

.events-container .today-btn {
  background: none;
  border: none;
  color: #007bff;
  cursor: pointer;
  font-weight: bold;
}

.events-container .week-calendar {
  display: flex;
  justify-content: space-between;
  text-align: center;
}

.events-container .day-cell {
  flex: 1;
  padding: 10px;
  border: 1px solid #eaeaea;
  /* border-radius: 5px; */
  /* position: relative; */
}

.events-container .day-text {
  display: block;
  font-size: 14px;
  color: #aaa;
}

.events-container .date-text {
  display: block;
  font-size: 16px;
  font-weight: bold;
  color: #aaa;
}

.events-container .active .day-text {
  color: #007bff;
  font-weight: bold;
}

.events-container .event {
  margin-top: 10px;
  text-align: left;
}

.events-container .avatars {
  display: flex;
}

.events-container .avatars img {
  width: 25px;
  height: 25px;
  border-radius: 50%;
  margin-right: -8px;
  border: 2px solid white;
}

.events-container .event-text {
  font-size: 12px;
  color: #333;
  margin-top: 5px;
}
