.replacement-header {
    font-weight: 600;
    font-size: 20px;
    color: #1681FF;
}


.replacements {
    display: flex;
    flex-wrap: wrap;
    gap: 15px;
}

.replacement-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 20px;
}

.replacement-card {
    background: #ffffff;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    transition: transform 0.2s ease-in-out;
}

.replacement-card:hover {
    transform: translateY(-5px);
}

.replacement-card p {
    margin: 5px 0;
}

.replacement-modal-content {
    padding: 10px;
}

.replacement-input {
    width: 100%;
    margin-bottom: 10px;
}

.replacement-card-client {
    padding: 0px 20px;
    padding-bottom: 20px;
    border-radius: 15px;
    width: 300px;
    background-color: #1681FF0D;

    .profile {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding-top: 10px;
        padding-bottom: 5px;

        .details {
            display: flex;
            gap: 10px;
            padding-top: 5px;
            flex-direction: row;
            align-items: center;
            justify-content: center;

            img {
                height: 50px;
                width: 50px;
            }

            .name {
                display: flex;
                flex-direction: column;

                .interviewer-name {
                    color: #54577A;
                    font-size: 16px;
                    font-weight: 600;
                }

                .joining-date {
                    font-size: 12px;
                    color: #54577A;
                    font-weight: 400;
                }
            }

        }

        .view-all {
            font-weight: 500;
            font-size: 16px;
            color: #54577A;
            cursor: pointer;
        }
    }

    .mail {
        margin-top: 15px;
        margin-left: 10px;
        display: flex;
        align-items: center;
        gap: 8px;
        color: #54577A;
        font-size: 14px;
        font-weight: 400;

        img {
            height: 19px;
        }
    }

    .pending {
        margin-left: 8px;
        margin-top: 15px;
        width: 100%;
        display: flex;
        justify-content: space-between;

        .note {
            display: flex;
            gap: 8px;
            font-weight: 400;
            font-size: 14px;
            color: #54577A;
            align-items: center;
        }
    }

    .last {
        margin-top: 20px;

        button {
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
        }
    }
}