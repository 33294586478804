
.resume-score-card {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  flex-direction: column;
  height: 300px;
  align-items: center;
  /* flex-direction: column; */
  /* justify-content: space-between; */
  gap: 25px;
}

.resume-score-card .score-text {
  display: flex;
  flex-direction: column;
  align-items: center;
  /* background-color: lightgreen; */
  width: 100px;
  z-index: 1;
  position: relative;
  bottom: -175px;
  left: 100px;
}

.resume-score-card .score-image-text {
  position: relative;
  width: 280px; 
  height: 280px; 
}

.resume-score-card .score-img {
  width: 100%;
}

.resume-score-card .polygone {
  position: absolute;
  width: 30px;
  height: 30px;
  transform-origin: center;
  transition: all 0.3s ease;
  /* top: 180px; */
  /* left: 24px; */
  rotate:38deg;
}

.score-img{
  width: 240px;
}

.resume-score-card button{
  width: 180px;
  background-color: rgba(22, 129, 255, 1);
  color: white;
  height: 40px;
}
.resume-score-card button:hover{
  width: 180px !important;
  background-color: rgba(22, 129, 255, 1) !important;
  color: white !important;
  height: 40px !important;

}