.contact-us {
    .section1 {

        color: #4D5163;

        .line1 {
            text-align: center;
            margin-top: 20px;
            font-size: 30px;
            font-weight: 600;

            .pink {
                color: #F46EBE;

            }
        }

        .line2 {
            text-align: center;
            margin-top: 10px;
            padding-bottom: 50px;
            font-size: 18px;
        }
    }

    .section2 {
        margin-top: 5vh;
        display: flex;
        /* padding: 20px 10px; */
        justify-content: space-between;
        gap: 30px;
        width: 90%;

        .contact-form {
            /* padding-left: 40px; */
            width: 45%;
            padding-left: 5vw;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;

            .text {
                color: #4D5163;
                /* font-family: Inter; */
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }

            .form {

                width: 100%;
                border: none;
                padding: 0;
                margin-top: 15px;
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                .line {
                    display: flex;
                    align-items: center;
                    gap: 15px;
                    width: 100%;
                    justify-content: center;
                }

                .input-field {
                    color: rgba(141, 140, 145, 0.80);
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 300;
                    line-height: 24px;
                    border-radius: 10px;
                    border: 1px solid rgba(162, 161, 168, 0.97);
                    padding: 0px 10px;
                    width: 100%;

                    /* 150% */
                    input,
                    select,
                    textarea {
                        border: none;
                        outline: none;
                    }
                }

                .organization-type {
                    margin-top: 10px;
                }

                .organization-name,
                .description {
                    margin-top: 10px;
                    display: flex;
                    align-items: center;
                    font-size: 14px;
                    color: #8D8C91CC;
                    width: 97%;
                }
            }

            button {
                margin-top: 10px;
                padding: 10px 20px;
                font-size: 14px;
                border-radius: 15px;
                border: 1px solid #88BEFD;
                background: #88BEFD;
            }

        }

        .contact-options {
            width: 45%;
            padding: 15px;
            border-radius: 16px;
            background: #F5F9FF;

            .item {
                margin-top: 10px;
                ;
                border-radius: 12px;
                background: #FFF;
                box-shadow: 0px 0px 16px 0px rgba(77, 104, 195, 0.12);
                display: flex;
                gap: 20px;
                padding: 10px;
                padding-bottom: 15px;

                .data {
                    display: flex;
                    flex-direction: column;
                    gap: 5px;
                    align-items: flex-start;
                    overflow: hidden;
                    color: #565E6C;
                    /* text-overflow: ellipsis; */
                    font-family: Lato;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;

                    .line1 {
                        color: #56A8FD;
                        font-size: 16px;
                    }

                    .mail {
                        color: #56A8FD;
                        text-decoration: underline;
                    }
                }
            }

            .social-media {
                padding-top: 20px;
                padding-bottom: 20px;
                display: flex;
                gap: 25px;

                a {
                    color: #4F6475;
                    font-size: 22px;
                    border: none;


                }
            }
        }
    }

    .faqs {
        margin-top: 20px;
        padding: 20px 30px;
        display: flex;
        flex-direction: column;
        align-items: center;

        .name {
            width: 30vw;
            color: #4D5163;
            text-align: center;
            font-size: 48px;
            font-style: normal;
            font-weight: 600;
            line-height: 109%;

            .pink {
                color: #F46EBE;
            }
        }

        .matter {
            width: 45vw;
            margin-top: 10px;
            color: #4D5163;
            text-align: center;
            /* font-family: Inter; */
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
        }

        .faqs-list {
            width: 90%;
            margin-top: 20px;
            display: flex;
            flex-direction: column;
            gap: 20px;
            padding-bottom: 30px;

            .faq {
                border-radius: 12px;
                background: #FFF;
                box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
                backdrop-filter: blur(100px);
                padding: 16px 20px;
                gap: 20px;
                align-self: stretch;
                color: #58A1FF;
                /* font-family: Lato; */
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: normal;

                .topline {
                    display: flex;
                    cursor: pointer;
                    justify-content: space-between;

                    .btn.rotate-180 {
                        transform: rotate(180deg);
                        transition: 0.5s;
                    }

                    .btn.rotate-0 {
                        transition: 0.5s
                    }
                }

                .answer {
                    margin-top: 10px;
                    color: #727272;
                    /* font-family: Inter; */
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }
            }

        }
    }
}