.interviewer-dashboard-card {
    border: 1px solid #E4E4E4;
    box-shadow: 0 0 8px 1.5px rgba(0, 0, 0, 0.1);
    padding: 10px;
    padding-top: 20px;
    padding-left: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-top: 10px;
    width: 280px;
    border-radius: 16px;

    .line1 {
        display: flex;
        gap: 50px;
        align-items: flex-start;

        .img {
            height: 50px;
            margin-top: 8px;
        }

        .illustration {
            height: 100px;
            margin-top: 0px;
        }
    }

    .value {
        color: #4798FC;
        font-size: 48px;
        font-weight: 600;
        margin-top: -20px;
    }
}

.interviewer-dashboard {
    display: flex;
    padding: 20px;
    justify-content: space-between;

    .left-content {
        width: 65%;

        .card-items {
            display: flex;
            gap: 30px;
        }

        .calendar {
            width: 100%;
            margin-top: 20px;
        }
    }

    .right-content {
        /* border: 2px solid black; */
        width: 400px;

        .upcoming-interviews {
            box-shadow: 0 0 8px 1.5px rgba(0, 0, 0, 0.1);
            border-radius: 16px;
            padding: 10px 5px;

            .line1 {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 10px;
                padding-bottom: 5px;

                .title {
                    font-size: 16px;
                    font-weight: 600;
                    margin-bottom: 0px;
                }

                .view-more {
                    color: #1681FF;
                    font-size: 14px;
                    font-weight: 500;
                    cursor: pointer;
                }
            }

            .cards {
                .interview-updates {
                    /* border: 2px solid black; */
                    margin: 5px;
                    margin-bottom: 8px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 5px;
                    border-radius: 8px;
                    background-color: #F7F7F7;
                    border-left: 5px solid #1681FF;

                    .details {
                        font-size: 12px;
                        color: #16151C;
                    }
                }

                button {
                    background-color: #1681FF;
                    border-radius: 4px;
                    font-size: 10px;
                    font-weight: 400;
                    padding: 0px 10px;
                }
            }
        }

        .missed-interviews {
            box-shadow: 0 0 8px 1.5px rgba(0, 0, 0, 0.1);
            border-radius: 16px;
            padding: 10px 5px;
            margin-top: 20px;

            .line1 {
                display: flex;
                justify-content: space-between;
                align-items: center;
                padding: 10px;
                padding-bottom: 5px;

                .title {
                    font-size: 16px;
                    font-weight: 600;
                    margin-bottom: 0px;
                }

                .view-more {
                    color: #1681FF;
                    font-size: 14px;
                    font-weight: 500;
                    cursor: pointer;
                }
            }

            .cards {
                .interview-updates {
                    /* border: 2px solid black; */
                    margin: 5px;
                    margin-bottom: 8px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    padding: 5px;
                    border-radius: 8px;
                    background-color: #F7F7F7;
                    border-left: 5px solid #1681FF;

                    .details {
                        font-size: 12px;
                        color: #16151C;
                    }
                }

                button {
                    background-color: #1681FF;
                    border-radius: 4px;
                    font-size: 10px;
                    font-weight: 400;
                    padding: 0px 10px;
                }
            }
        }
    }
}