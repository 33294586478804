.selected-candidate-list {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
}

.selected-candidate-list .selected-candidate {
    background: #f9f9f9;
    padding: 15px;
    border-radius: 10px;
    box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
    width: 300px;
}

.selected-candidate-list .candidate-card h2 {
    margin-bottom: 10px;
    color: #333;
}

.selected-candidate-list .candidate-card p {
    margin: 5px 0;
}