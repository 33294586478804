.organization-terms-heading {
	text-align: center;
	font-size: 32px;
	/* margin-bottom: 30px; */
}


.terms-content p {
	font-size: 16px;
	line-height: 1.5;
	text-align: left;
}

.edit-terms-button {
	background-color: var(--color-primary);
	width: 160px;
}

.modal-form {
	max-width: 600px;
	margin: 0 auto;
}

.form-buttons {
	display: flex,

}