.custom-table-scheduled-interviews {
    table {
        border-collapse: collapse;
        border-radius: 10px;

        thead {

            border-radius: 10px;

            th {
                background-color: #1681FF;
                color: white;
                font-size: 14px;
                font-weight: 400;
            }
        }

        tbody {
            tr {
                text-align: center;

                td {
                    font-size: 14px;
                    font-weight: 400;

                    p {
                        margin: 5px;
                        padding: 0;
                    }
                }
            }

            tr:nth-child(odd) {
                background-color: #F6FAFF;
            }

            tr:nth-child(even) {
                background-color: #ffffff;
            }
        }
    }
}

.expandable-review {
    display: flex;
    flex-direction: column;
    gap: 10px;

}