.rctr-dash-card{
  display: flex;
  flex-direction: column;
  width: 240px;
  /* background-color: lightblue; */
  /* box-shadow:3px 3px 10px #0000000D; */
  box-shadow: 4px 4px 4px 0 rgba(0, 0, 0, 0.05);

  cursor: pointer;
  border-radius: 8px;
  border: 1px solid #FFFFFF;
  padding: 15px;
  .count-card-image{
    display: flex;
    flex-direction: row;
  }
   .count-card{
    width: 60px;
    height: 60px;
    position: relative;    
    display: flex;
    top: -40px;
    left: -60px;
    border-radius: 20px;
    background-color: white;
    border: 1px solid #0827771A;
    justify-content: center;
    align-items: center;
  }
  
  h2{
    font-size: 14px;
    font-weight: 400px;
    text-align: left;
    color: black;
    opacity: 50%;
    display: inline;
  }

}
.rctr-dash-card .count-card{
  h3{
    color: var(--color-primary);    
    font-size: 24px;
    font-weight:600;
  }
}
.rctr-dash-card .count-card-image{
  display: flex;
  justify-content: space-evenly;
  img{
    width: 76px;
  }
}

.rctr-dash-card .card-text{
  font-size: 14px;
  font-weight: 400px;
  text-align: left;
}


