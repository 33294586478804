.upload-container {
    width: 100%;
    max-width: 500px;
    padding: 20px;
    border: 2px dashed #1681FF;
    border-radius: 10px;
    background-color: #e6f7ff;
    text-align: center;
    transition: all 0.3s ease-in-out;
}

.upload-container:hover {
    border-color: #1681FF;
    background-color: #cceeff;
}

.upload-button {
    width: 100%;
    font-size: 14px;
    color: white;
    background-color: #1681FF;
    border: none;
    padding: 10px 10px;
    border-radius: 5px;
    cursor: pointer;
}

.upload-button:hover {
    background-color: #0056b3;
}