.tickets {
    /* margin-top: -25px; */

    .line {
        display: flex;
        justify-content: space-between;
        align-items: center;

        button {
            padding: 10px;
            margin: 0;
            border: none;
            border-radius: 5px;
            margin-right: 20px;
            color: white;
            background-color: #076ADB;
            cursor: pointer;
        }
    }

    .tickets-section {
        display: flex;
        height: 78vh;
        width: 90vw;
        border-radius: 16px;
        border: 1px solid rgba(162, 164, 167, 0.48);
        background: #FFF;
        box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.22);

        .all-tickets {
            width: 25%;
            border-right: 1px solid rgba(162, 164, 167, 0.48);
            padding: 15px;
            max-height: 100%;
            overflow-y: scroll;
            overflow-x: hidden;


            .toggle {
                margin-top: 5px;
                display: flex;
                gap: 15px;
                color: #000;
                /* font-family: Lexend; */
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 24px;
                margin-bottom: 20px;

                /* height: 50px; */
                .toggle-item {
                    cursor: pointer;
                }

                .active {
                    color: #076ADB;
                    border-bottom: 4px solid #1681FF;
                }
            }

            .search {
                background-color: white;
                display: flex;
                align-items: center;
                padding: 0 10px;
                margin: 0;
                border-radius: 10px;
                border: 1px solid rgba(162, 161, 168, 0.40);
                margin-bottom: 20px;
            }

            .tickets-list {
                overflow-y: scroll;
                max-height: 74%;
                scrollbar-width: thin;
                scrollbar-color: rgba(0, 0, 0, 0.2) transparent;

                .ticket {

                    border-top: 1px solid rgba(172, 192, 215, 0.5);
                    cursor: pointer;

                    .line1 {
                        display: flex;
                        justify-content: space-between;
                        padding-top: 10px;

                        .name {
                            color: #076ADB;
                            /* font-family: Poppins; */
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 24px;
                        }

                        .date {
                            color: #868D93;
                            /* font-family: Inter; */
                            font-size: 10px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: normal;
                        }

                        .status {
                            font-size: 10px;
                            padding: 5px 10px;
                            border-radius: 10px;
                        }
                    }


                    .line2 {
                        .category {
                            overflow: hidden;
                            color: #455563;
                            text-overflow: ellipsis;
                            /* font-family: Poppins; */
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 400;
                            line-height: 24px;

                            .name {
                                color: #455563;
                                /* font-family: Poppins; */
                                font-size: 14px;
                                font-style: normal;
                                font-weight: 700;
                                line-height: 24px;
                            }
                        }
                    }
                }
            }
        }

        .empty-ticket {
            width: 75%;
            display: flex;
            align-items: center;
            justify-content: center;
        }

        .particular-ticket {
            padding: 10px;
            width: 75%;

            .line {
                padding: 5px 5px;
                border-bottom: 1px solid #E8EEF4;
                display: flex;
                justify-content: space-between;
                align-items: center;

                .name {
                    color: #076ADB;
                    font-family: Poppins;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: normal;

                    .id {
                        margin-left: 10px;
                        color: #909090;
                        font-family: Poppins;
                        font-size: 12px;
                        font-style: normal;
                        font-weight: 400;
                        line-height: normal;
                    }
                }

                .category {
                    color: #909090;
                    /* font-family: Poppins; */
                    font-size: 13px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: normal;
                }

                .right {
                    display: flex;
                    gap: 10px;
                    align-items: center;

                    .floating-notification {
                        display: flex;
                        gap: 10px;
                        align-items: center;
                    }

                    .floating-notification p {
                        margin: 0;
                        font-weight: 600;
                    }

                    .floating-notification button {
                        padding: 5px 10px;
                        border: 2px solid #1890ff;
                        color: #1890ff;
                        background-color: transparent;
                        cursor: pointer;
                        border-radius: 5px;
                    }

                    .floating-notification button:hover {
                        background: #0073e6;
                        color: white;
                    }

                    .statuss {
                        color: #909090;
                        font-size: 14px;
                        font-weight: 400;


                        .type {
                            margin-left: 10px;
                            color: white;
                            font-size: 13px;
                            background-color: #076ADB;
                            padding: 10px;
                            border-radius: 15px;
                        }

                        .pending {
                            background-color: #FEF9EE;
                            color: #98690C;
                        }

                        .completed {
                            background-color: #EEFDF3;
                            color: #117B34;
                        }
                    }

                }
            }

            .replies-content {
                height: 75%;
                overflow: auto;
                scrollbar-width: thin;
                scrollbar-color: rgba(52, 52, 52, 0.2) transparent;
            }

            .content {
                margin-top: 15px;
                ;
                border-radius: 16px;
                padding: 20px 10px;
                border: 1px solid #E8EEF4;
                background: rgba(173, 211, 255, 0.27);

                .line1 {
                    display: flex;
                    justify-content: space-between;

                    .subject {
                        color: #2E4048;
                        font-size: 18px;
                        font-weight: 600;

                        .value {
                            margin-left: 15px;
                            font-size: 16px;
                            font-weight: 500;
                        }
                    }

                    .date {
                        color: #455563;
                        font-size: 12px;
                        font-weight: 400;
                    }
                }

                .line2 {
                    margin-top: 15px;
                    color: #384952;
                    font-size: 14px;
                    font-weight: 400;
                }

                .attachments {
                    margin-top: 10px;
                    font-size: 12px;
                    font-style: italic;
                }
            }

            .replies {
                margin-top: 10px;

                .replies-list {
                    overflow-y: auto;
                    scrollbar-width: thin;
                }

                .reply {
                    padding: 20px 10px;
                    border-radius: 16px;
                    border: 1px solid #E8EEF4;
                    background: #FFFAFE;

                    .line1 {
                        padding: 0px 0px;
                        display: flex;
                        justify-content: space-between;

                        .name {
                            color: #2E4048;
                            font-size: 16px;
                            font-weight: 600;
                        }

                        .date {
                            color: #455563;
                            font-size: 12px;
                            font-weight: 400;
                        }
                    }

                    .line2 {
                        margin-top: 10px;
                        color: #384952;
                        font-size: 14px;
                        font-weight: 400;
                        margin-bottom: 10px;
                    }

                    .attachments {
                        font-size: 12px;
                        font-style: italic;
                    }
                }
            }

            /* .floating-notification {
                position: absolute;
                bottom: 80px;
                left: 50%;
                transform: translateX(-50%);
                background: #fff;
                padding: 10px 20px;
                border-radius: 8px;
                box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
                display: flex;
                align-items: center;
                gap: 10px;
                z-index: 1000;
            } */



            .reply-box {
                margin-top: 15px;
                display: flex;
                align-items: center;
                justify-content: space-between;
                border-radius: 8px;
                border: 1px solid rgba(119, 178, 209, 0.49);
                background: #FFF;

                textarea {
                    padding: 8px;
                    outline: none;
                    width: 100%;
                    border: none;

                    /* border-radius: 8px;
                    border: 1px solid #ccc; */
                }

                .another-div {
                    display: flex;
                    margin-right: 15px;
                    gap: 10px;
                }

                input {
                    margin-top: 5px;
                    outline: none;
                    border: none;
                }

                button {
                    display: flex;
                    align-items: center;
                    background-color: #076ADB;
                    padding: 10px;
                    border: none;
                    border-radius: 50% 50%;
                    color: white;
                    cursor: pointer;
                    margin-right: 10px;
                }
            }
        }

    }
}