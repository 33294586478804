/* Enhanced cosmic background */
.coming-soon-container,
.welcome-container {
    position: relative;
    min-height: 100vh;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: linear-gradient(135deg, #2e1065 0%, #5b21b6 50%, #7e22ce 100%);
    overflow: hidden;
}

/* Stars background */
.stars {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image:
        radial-gradient(2px 2px at 20px 30px, white, rgba(0, 0, 0, 0)),
        radial-gradient(2px 2px at 40px 70px, white, rgba(0, 0, 0, 0)),
        radial-gradient(2px 2px at 50px 160px, white, rgba(0, 0, 0, 0)),
        radial-gradient(2px 2px at 90px 40px, white, rgba(0, 0, 0, 0)),
        radial-gradient(2px 2px at 130px 80px, white, rgba(0, 0, 0, 0)),
        radial-gradient(2px 2px at 160px 120px, white, rgba(0, 0, 0, 0)),
        radial-gradient(2px 2px at 200px 50px, white, rgba(0, 0, 0, 0)),
        radial-gradient(2px 2px at 240px 90px, white, rgba(0, 0, 0, 0)),
        radial-gradient(2px 2px at 280px 130px, white, rgba(0, 0, 0, 0)),
        radial-gradient(2px 2px at 320px 70px, white, rgba(0, 0, 0, 0)),
        radial-gradient(2px 2px at 360px 110px, white, rgba(0, 0, 0, 0)),
        radial-gradient(2px 2px at 400px 50px, white, rgba(0, 0, 0, 0)),
        radial-gradient(2px 2px at 440px 90px, white, rgba(0, 0, 0, 0)),
        radial-gradient(2px 2px at 480px 130px, white, rgba(0, 0, 0, 0)),
        radial-gradient(2px 2px at 520px 70px, white, rgba(0, 0, 0, 0)),
        radial-gradient(2px 2px at 560px 110px, white, rgba(0, 0, 0, 0)),
        radial-gradient(2px 2px at 600px 50px, white, rgba(0, 0, 0, 0)),
        radial-gradient(2px 2px at 640px 90px, white, rgba(0, 0, 0, 0)),
        radial-gradient(2px 2px at 680px 130px, white, rgba(0, 0, 0, 0));
    background-size: 700px 400px;
    z-index: 1;
    animation: twinkle 10s ease-in-out infinite alternate;
}

@keyframes twinkle {
    0% {
        opacity: 0.5;
    }

    50% {
        opacity: 1;
    }

    100% {
        opacity: 0.7;
    }
}

/* Cosmic nebula effect */
.twinkling {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: radial-gradient(circle at 30% 60%, rgba(159, 122, 234, 0.3) 0%, transparent 50%),
        radial-gradient(circle at 70% 40%, rgba(236, 72, 153, 0.2) 0%, transparent 40%),
        radial-gradient(circle at 50% 50%, rgba(139, 92, 246, 0.2) 0%, transparent 60%),
        radial-gradient(circle at 80% 70%, rgba(124, 58, 237, 0.3) 0%, transparent 30%);
    z-index: 2;
    animation: move-background 30s linear infinite;
}

/* Cosmic dust particles */
.particle {
    position: absolute;
    border-radius: 50%;
    background: rgba(255, 255, 255, 0.7);
    box-shadow: 0 0 10px 2px rgba(255, 255, 255, 0.3);
    z-index: 3;
    pointer-events: none;
}

@keyframes move-background {
    0% {
        transform: translate(0, 0) scale(1);
    }

    25% {
        transform: translate(-20px, 10px) scale(1.02);
    }

    50% {
        transform: translate(-40px, 20px) scale(1.05);
    }

    75% {
        transform: translate(-20px, 10px) scale(1.02);
    }

    100% {
        transform: translate(0, 0) scale(1);
    }
}

/* Content styling */
.content-wrapper {
    position: relative;
    z-index: 10;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 2rem;
    max-width: 800px;
    width: 100%;
    background: rgba(91, 33, 182, 0.2);
    backdrop-filter: blur(10px);
    border-radius: 20px;
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2);
}

.logo-container {
    margin-bottom: 1.5rem;
}

.logo {
    height: auto;
}

.maintenance-text {
    margin-bottom: 1rem;
}

.badge {
    background-color: rgba(236, 72, 153, 0.2);
    color: var(--accent);
    padding: 0.25rem 0.75rem;
    border-radius: 9999px;
    font-size: 0.75rem;
    font-weight: 600;
    margin-bottom: 0.5rem;
    display: inline-block;
    border: 1px solid rgba(236, 72, 153, 0.3);
}

.maintenance-text h3 {
    font-size: 1.25rem;
    font-weight: 500;
    color: var(--text-light);
    opacity: 0.8;
}

.launch-text h1 {
    font-size: 3rem;
    font-weight: 800;
    line-height: 1.2;
    margin-bottom: 1.5rem;
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.3);
}

@media (min-width: 768px) {
    .launch-text h1 {
        font-size: 4.5rem;
    }
}

.text-white {
    color: white;
}

.text-gradient {
    background: linear-gradient(90deg, #f43f5e, #ec4899);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
    text-shadow: none;
}

.ugadhi-message {
    margin-bottom: 2rem;
    font-size: 1.25rem;
    color: #fef3c7;
    text-shadow: 0 0 5px rgba(254, 243, 199, 0.3);
}

/* Enhanced countdown styling */
.countdown-container {
    display: flex;
    justify-content: center;
    gap: 1rem;
    margin-bottom: 2rem;
}

@media (min-width: 640px) {
    .countdown-container {
        gap: 2rem;
    }
}

.countdown-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 70px;
    background: rgba(91, 33, 182, 0.3);
    padding: 1rem 0.5rem;
    border-radius: 12px;
    backdrop-filter: blur(5px);
    border: 1px solid rgba(255, 255, 255, 0.1);
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.countdown-value {
    font-size: 2.5rem;
    font-weight: 700;
    line-height: 1;
    color: white;
    text-shadow: 0 0 10px rgba(255, 255, 255, 0.5);
}

@media (min-width: 640px) {
    .countdown-value {
        font-size: 3.5rem;
    }
}

.countdown-label {
    font-size: 0.875rem;
    color: rgba(255, 255, 255, 0.7);
    margin-top: 0.5rem;
    font-weight: 500;
}

.description {
    max-width: 600px;
    margin-bottom: 2rem;
    font-size: 1.125rem;
    line-height: 1.6;
    color: rgba(255, 255, 255, 0.9);
}

/* Button styling */
.learn-more-btn,
.get-started-btn {
    background: linear-gradient(90deg, var(--primary), var(--primary-light));
    color: white;
    border: none;
    padding: 0.75rem 1.5rem;
    border-radius: 9999px;
    font-weight: 600;
    cursor: pointer;
    transition: all 0.3s ease;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    box-shadow: 0 4px 14px rgba(0, 0, 0, 0.25);
    position: relative;
    overflow: hidden;
}

.learn-more-btn::before,
.get-started-btn::before {
    content: "";
    position: absolute;
    top: 0;
    left: -100%;
    width: 100%;
    height: 100%;
    background: linear-gradient(90deg, transparent, rgba(255, 255, 255, 0.2), transparent);
    transition: all 0.6s ease;
}

.learn-more-btn:hover::before,
.get-started-btn:hover::before {
    left: 100%;
}

.learn-more-btn:hover,
.get-started-btn:hover {
    background: linear-gradient(90deg, var(--primary-light), var(--primary));
    transform: translateY(-2px);
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.3);
}

.arrow {
    transition: transform 0.3s ease;
}

.learn-more-btn:hover .arrow {
    transform: translateX(4px);
}

/* Welcome page styles */
.welcome-content {
    text-align: center;
    max-width: 600px;
    padding: 2rem;
    background: rgba(91, 33, 182, 0.2);
    backdrop-filter: blur(10px);
    border-radius: 20px;
    box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2);
}

.welcome-content h1 {
    font-size: 3rem;
    font-weight: 800;
    margin-bottom: 1.5rem;
    background: linear-gradient(90deg, #f43f5e, #ec4899);
    -webkit-background-clip: text;
    background-clip: text;
    color: transparent;
}

.welcome-content p {
    font-size: 1.25rem;
    line-height: 1.6;
    margin-bottom: 1rem;
    color: rgba(255, 255, 255, 0.9);
}