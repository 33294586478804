.options-flex{
  display: flex;
  gap: 20px;
  width: 95%;
  margin: 0 auto;
}
.basic-details-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.basic-details-container .basic-details-card {
  width: 95%;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  padding: 20px;
}

.basic-details-container .basic-details-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
}

.basic-details-container .basic-details-title {
  font-size: 20px;
  font-weight: 600;
  margin: 0;
  color: #333;
}

.basic-details-container .save-button .edit-details-button {
  display: flex;
  align-items: center;
  gap: 8px;
  background-color: #0080ff;
  color: white;
  border: none;
  border-radius: 6px;
  padding: 8px 16px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
}

.basic-details-container .form-divider {
  height: 1px;
  background-color: #e0e0e0;
  margin-bottom: 20px;
}

.basic-details-container .form-row {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 15px;
}

.basic-details-container .form-group {
  flex: 1;
  min-width: 250px;
    /* flex: 1 1; */
  min-width: 250px;
    background-color: #f0f0f0;
    border-radius: 8px;
    padding: 7px 25px;
    margin-bottom: 0px;
}

.basic-details-container .form-group.full-width {
  flex-basis: 100%;
}


.basic-details-container label {
  display: block;
  font-size: 14px;
  font-weight: 500;
  color: #333;

}
.basic-details-container .form-group label{
  display: flex;
  align-items: center;
}

.basic-details-container .form-input {
  background-color: #f0f0f0;
  border-radius: 6px;
  padding: 12px 15px;
  font-size: 14px;
  color: #666;
  min-height: 20px;
}

.basic-details-container .form-actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.basic-details-container .save-button {
  background-color: #f0f0f0;
  color: #333;
  border: none;
  border-radius: 6px;
  padding: 10px 20px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: background-color 0.2s;
}

.basic-details-container .save-button:hover {
  background-color: #e0e0e0;
}

@media (max-width: 768px) {
  .basic-details-container .form-group {
    flex-basis: 100%;
  }

  .basic-details-container .form-row {
    gap: 15px;
  }
}

