.layout {
    display: flex;
    flex-direction: column;
    height: 100vh;
}

/* Sidebar */
.sider {
    width: 100px;
    background-color: rgba(0, 23, 68, 1);
    height: 100vh;
    position: fixed;
    left: 0;
    top: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow-y: none;
    box-shadow: 2px 0 5px rgba(0, 0, 0, 0.1);
    border-top-right-radius: 3px;
    border-bottom-right-radius: 3px;
    z-index: 1001;
}

.sider .logo {
    margin-top: 0px;
}

.sider .logo img {
    height: 84px;
}

/* Sidebar Text */
.sider h2 {
    font-size: 18px;
    margin-bottom: 18px;
    cursor: pointer;
    transition: color 0.3s ease;
}

.sider h2:hover {
    color: #007bff;
}

.sider .section {
    display: flex;
    align-items: center;
    flex-direction: column;
    /* padding: 10px; */
    margin-top: 20px;
    cursor: pointer;
    position: relative;
    transition: background-color 0.3s ease;
    border-radius: 5px;
    width: 100%;
}

.sider .selected-option>img {
    opacity: 1
}


.sider .section img {
    height: 25px;
    opacity: 0.6;
}

.sider .selected-option::before {
    content: "";
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
    width: 5px;
    background-color: rgba(255, 156, 171, 1);
    border-top-right-radius: 10px;
    border-bottom-right-radius: 10px;
}


.sider .section .name {
    font-size: 13px;
    color: rgba(207, 216, 220, 1);
    margin-top: 2px;
    text-align: center;
}

/* Top Section */
.top {
    width: 100%;
    height: 60px;
    background-color: white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-left: 20px;
    position: fixed;
    top: 0;
    z-index: 1000;
    box-shadow: 0px 4px 10px rgba(91, 91, 91, 0.13);

    .ticket {
        display: flex;
        align-items: center;
        margin-top: 0;
        right: 280px;
        padding: 8px;
        cursor: pointer;
        background-color: #3393FF;
        border-radius: 50% 50%;
        position: fixed;
    }

    .profile {
        height: 40px;
        position: fixed;
        right: -250px;
        margin-top: 0;
        cursor: pointer;

        .profile-section {
            display: flex;
            gap: 10px;
            cursor: pointer;

        }
    }

}

.center {
    margin-left: 120px;
    margin-top: 85px;
    margin-right: 30px;
    overflow-x: hidden;
    flex-grow: 1;
    background-color: #FFF;
    ;
}