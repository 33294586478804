.received-applications-client {
    padding: 20px;

    .job-post-header {
        padding: 5px;

        .job-post-details {
            display: flex;
            justify-content: space-between;

            .left-side {
                display: flex;
                align-items: center;
                gap: 10px;
                justify-content: center;
                font-size: 22px;
                font-weight: 400;
                color: #171A1F;
            }

            .cart {
                button {
                    background-color: #1681FF;
                    border-radius: 6px;
                    height: 40px;
                    padding: 5px 15px;
                }
            }
        }

        .ctc-details {
            margin-top: 10px;
            display: flex;
            gap: 20px;

            .ctc {
                display: flex;
                align-items: center;
                gap: 10px;
                font-size: 14px;
                font-weight: 700;
                color: #282E38;
            }

            .job_title {
                font-size: 14px;
                font-weight: 700;
                color: #282E38;
                display: flex;
                align-items: center;
                gap: 10px;

                img {
                    height: 28px;
                    width: 28px;
                    color: black
                }

                ;
            }
        }

        .job-description {
            margin-top: 10px;
            width: 80%;
            color: #6E7787;
            font-size: 14px;
            font-weight: 400;
        }
    }

    .applications {
        margin-top: 15px;
        display: flex;
        flex-wrap: wrap;
        gap: 15px;
        grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    }

    @media (min-width: 1200px) {
        .applications {
            grid-template-columns: repeat(4, 1fr);
            /* Fixed 4 cards for big screens */
        }
    }

    .application-card {
        /* border: 2px solid black; */
        width: 330px;
        padding: 15px;
        box-shadow: 0 0 5px 0.3px rgba(0, 0, 0, 0.5);
        border-radius: 10px;

        .line1 {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .name {
                .candidate-name {
                    color: #54577A;
                    font-size: 16px;
                    font-weight: 600;
                    margin-top: 0px;
                    padding-top: 0;
                }

                .email {
                    margin-top: 0px;
                    padding-top: 0px;
                    font-size: 12px;
                    font-weight: 400;
                }
            }

            .status {

                font-size: 12px;
                font-size: 12px;

                font-weight: 400;

                .status-green {
                    border-radius: 14px;
                    background-color: #EEFDF3;
                    padding: 10px;
                    color: #117B34;
                }

                .status-red {
                    padding: 10px;
                    border-radius: 14px;
                    background-color: #FDEFF3;
                    color: #E8618C;
                }
            }
        }

        .line2 {
            display: flex;
            gap: 10px;
            margin-top: 10px;
            align-items: center;
            /* justify-content: center; */

            .line2-option {
                background-color: #1681FF0D;
                padding: 5px 10px;
                border-radius: 23px;
                display: flex;
                align-items: center;
                gap: 10px;
                color: #555555;
                font-size: 12px;
                font-weight: 500;

                .notice {
                    width: 25px;
                    height: 25px;
                }
            }
        }

        .line3 {
            margin-top: 10px;
            display: flex;
            flex-direction: column;
            gap: 5px;
            color: #555555;
            font-size: 12px;
            font-weight: 500;

            .line3-option {
                background-color: #1681FF0D;
                padding: 5px 10px;
                border-radius: 23px;
                display: flex;
                align-items: center;
                gap: 10px;
                width: fit-content;
            }
        }

        .last {
            margin-top: 15px;
            /* padding: 0px 15px; */
            display: flex;
            justify-content: space-between;

            .view-details {
                border-radius: 5px;
                color: #464F59;
                border: 1px solid #464F59;
                background-color: white;
                font-size: 14px;
                font-weight: 400;
            }

            .add-to-compare {
                background-color: #1681FF;
                font-size: 14px;
                font-weight: 400;
            }

            button {
                height: 40px;
            }
        }

    }

    .application-card:hover {
        scale: 1.01;
        animation: 0.3s ease-in-out;
    }
}