/* CandidateApplications.css */

/* Style the table header */
.cand-applications-conatiner .ant-table th {
  background-color: #FAFAFB !important;
  color: #565E6C!important; /* Header text color */
  text-align: center !important; /* Center-align header text */
  padding: 10px !important; /* Add padding to header cells */
  font-size: 16px !important; /* Increase font size for better readability */
}

/* Style the table data (cells) */
.cand-applications-conatiner .ant-table td {
  text-align: center !important; /* Center-align table data */
  padding: 8px !important; /* Add padding to table cells */
}

/* Style the actions column button */
.cand-applications-conatiner .ant-table .ant-btn {
  background-color: #1681FF !important; /* Button background color */
  color: #fff !important; /* Button text color */
  border: none !important; /* Remove button border */
  font-size: 12px !important; /* Button font size */
  width: 150px !important; /* Set button width */
  cursor: pointer !important; /* Change cursor to pointer on hover */
  margin: 0 auto !important; /* Center the button */
  display: block !important; /* Display button as a block element */
}
.cand-applications-conatiner .ant-table-tbody > tr {
  height: 70px !important; /* Row height for all rows */
}
.cand-applications-conatiner .ant-table{
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25)
}


/* Optional: Style the tag color in 'Application Status' column */
.cand-applications-conatiner .ant-tag.green {
  background-color: green !important;
  color: white !important;
}

.cand-applications-conatiner .ant-tag.red {
  background-color: red !important;
  color: white !important;
}

.cand-applications-conatiner .ant-tag.blue {
  background-color: blue !important;
  color: white !important;
}
