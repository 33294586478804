.recruiters-score-card {
  width: 250px;
  background: white;
  padding: 15px;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.rctr-summery-container {
  display: flex;
  gap: 10px;
}

.rctr-onproces-card-container .title {
  color: #1E88E5;
  font-size: 18px;
  font-weight: bold;
  margin-bottom: 10px;
}

.rctr-onproces-card-container .chart-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rctr-onproces-card-container .total {
  position: absolute;
  font-size: 18px;
  font-weight: bold;
  color: #333;
}

.rctr-onproces-card-container .stats {
  margin-top: 10px;
}

.rctr-onproces-card-container .stat {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  font-size: 14px;
  margin-top: 5px;
}

.rctr-onproces-card-container .dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  display: inline-block;
}

.rctr-onproces-card-container .label {
  color: #555;
}

.rctr-onproces-card-container .percentage {
  font-weight: bold;
}


/* ant design  */

/* Container styling */
.job-listing-container {
  /* max-width: 1200px; */
  width: 100%;
  /* margin: 0 auto; */
  /* padding: 20px; */
}

/* Table styling */
.job-listing-table {
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
}

/* Override Ant Design table styles */
.job-listing-table .ant-table {
  background-color: #fff;
}

.job-listing-table .ant-table-thead>tr>th {
  background-color: #f5f7fa;
  color: #333;
  font-weight: 600;
  padding: 16px;
  border-bottom: 1px solid #e8e8e8;
}

.job-listing-table .ant-table-tbody>tr>td {
  padding: 16px;
  border-bottom: 1px solid #f0f0f0;
}

.job-listing-table .ant-table-tbody>tr:nth-child(even) {
  background-color: #f9f9f9;
}

.job-listing-table .ant-table-tbody>tr:hover>td {
  background-color: #f0f7ff;
}

/* Candidate count styling */
.candidates-count {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #666;
}

/* Job title styling */
.job-listing-table .ant-table-tbody>tr>td:first-child {
  font-weight: 500;
  color: #333;
}


.rctr-sum-int-card {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.rctr-onproces-card-container {
  gap: 10px;
  display: flex;
  /* justify-content: center; */
  /* align-items: center; */
  flex-direction: column;
  text-align: left;
}

.rctr-onproces-card {
  display: flex;
  align-items: center;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
  background-color: white;
  width: 271px;
}

.profile-img {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 10px;
}

.info {
  flex: 1;
}

.name {
  font-size: 16px;
  color: #333;
  margin: 0;
}

.role {
  font-size: 14px;
  color: gray;
  margin: 0;
}

.status {
  padding: 5px 10px;
  font-size: 12px;
  font-weight: bold;
  color: #b58900;
  /* background-color: #fff3cd; */
  border-radius: 15px;
}

/* Responsive adjustments */
@media (max-width: 768px) {

  .job-listing-table .ant-table-thead>tr>th,
  .job-listing-table .ant-table-tbody>tr>td {
    padding: 12px 8px;
    font-size: 14px;
  }
}